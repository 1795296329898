@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Poppins:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
    font-family: 'avantgarde_bk_btdemi';
    src: url('../fonts/avantgardedemibt-webfont.woff2') format('woff2'),
         url('../fonts/avantgardedemibt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avantgarde_bk_btdemi_oblique';
    src: url('../fonts/avantgardedemiobliquebt-webfont.woff2') format('woff2'),
         url('../fonts/avantgardedemiobliquebt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avantgarde_bk_btbook';
    src: url('../fonts/avantgardebookbt-webfont.woff2') format('woff2'),
         url('../fonts/avantgardebookbt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'the_secretregular';
    src: url('../fonts/thesecret-regular-webfont.woff2') format('woff2'),
         url('../fonts/thesecret-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CenturyGothic-Bold';
    src: url('../fonts/CenturyGothic-Bold.woff2') format('woff2'),
        url('../fonts/CenturyGothic-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/CenturyGothic.woff2') format('woff2'),
        url('../fonts/CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/Helvetica.woff2') format('woff2'),
        url('../fonts/Helvetica.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/Helvetica-Bold.woff2') format('woff2'),
        url('../fonts/Helvetica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Bold.woff2') format('woff2'),
        url('../fonts/MyriadPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-BoldCond.woff2') format('woff2'),
        url('../fonts/MyriadPro-BoldCond.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-BoldCondIt.woff2') format('woff2'),
        url('../fonts/MyriadPro-BoldCondIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Cond.woff2') format('woff2'),
        url('../fonts/MyriadPro-Cond.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-BoldIt.woff2') format('woff2'),
        url('../fonts/MyriadPro-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-CondIt.woff2') format('woff2'),
        url('../fonts/MyriadPro-CondIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Light.woff2') format('woff2'),
        url('../fonts/MyriadPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Semibold.woff2') format('woff2'),
        url('../fonts/MyriadPro-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
        url('../fonts/MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-SemiboldIt.woff2') format('woff2'),
        url('../fonts/MyriadPro-SemiboldIt.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'bestloversregular';
    src: url('../fonts/bestlovers.woff2') format('woff2'),
         url('../fonts/bestlovers.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'optilordswashregular';
    src: url('../fonts/optilordswash.woff2') format('woff2'),
         url('../fonts/optilordswash.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futurabold';
    src: url('../fonts/futura_bold_font.woff2') format('woff2'),
         url('../fonts/futura_bold_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'futurabold_italic';
    src: url('../fonts/futura_bold_italic_font.woff2') format('woff2'),
         url('../fonts/futura_bold_italic_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futurabook';
    src: url('../fonts/futura_book_font.woff2') format('woff2'),
         url('../fonts/futura_book_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futurabook_italic';
    src: url('../fonts/futura_book_italic_font.woff2') format('woff2'),
         url('../fonts/futura_book_italic_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuraextra_black';
    src: url('../fonts/futura_extra_black_font.woff2') format('woff2'),
         url('../fonts/futura_extra_black_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuraheavy';
    src: url('../fonts/futura_heavy_font.woff2') format('woff2'),
         url('../fonts/futura_heavy_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuraheavy_italic';
    src: url('../fonts/futura_heavy_italic_font.woff2') format('woff2'),
         url('../fonts/futura_heavy_italic_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuralight';
    src: url('../fonts/futura_light_bt.woff2') format('woff2'),
         url('../fonts/futura_light_bt.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuralight';
    src: url('../fonts/futura_light_font.woff2') format('woff2'),
         url('../fonts/futura_light_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuralight_italic';
    src: url('../fonts/futura_light_italic_font.woff2') format('woff2'),
         url('../fonts/futura_light_italic_font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuramedium';
    src: url('../fonts/futura_medium_bt.woff2') format('woff2'),
         url('../fonts/futura_medium_bt.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuramedium';
    src: url('../fonts/futura_medium_condensed_bt.woff2') format('woff2'),
         url('../fonts/futura_medium_condensed_bt.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {border: 0;font-family: inherit;font-size: 100%;font-style: inherit;font-weight: inherit;margin: 0;outline: 0;padding: 0;vertical-align: baseline;}

html {
    -webkit-text-size-adjust: none;
}


:focus {
    outline: 0;
}

ol,
ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    background: url(../images/black-concrete-texture.jpg) no-repeat;
    background-size: cover;
}

/*body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    background: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
    background-color: #98684a;
    outline: 0px solid slategrey;
}
*/
cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
}
.btn {
    color: #fff;
    padding: 12px 20px;
    font-size: 15px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    text-transform: uppercase;
    position: relative;
    background: #6e1d24;
    font-family: 'avantgarde_bk_btdemi';
    border-radius: 5px;
    display: inline-block;
    text-align: center;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    padding: 12px 10px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius: 5px;
    height: 48px;
    font-size: 15px;
    color: #555555;
   font-family: 'avantgarde_bk_btbook';
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    margin: 15px 0px 10px 0px;
    padding: calc(8px + (14 - 8) * ((100vw - 300px) / (1600 - 300))) calc(8px + (24 - 8) * ((100vw - 300px) / (1600 - 300)));
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #8F0F33;
    border: 0px;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
   font-family: 'avantgarde_bk_btdemi';
    -webkit-box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
    box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.btn:hover,
.button:hover,
button:hover {
    color: #000;
    background: #ffdf00;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
    color: #6e1d24;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #FFDF00;
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: normal;
    line-height: 1.1;
    color: #1a1a1a;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
}

h1 {
    font-size: calc(17px + (42 - 17) * ((100vw - 300px) / (1600 - 300)));
}

h2 {
    font-size: calc(17px + (35 - 17) * ((100vw - 300px) / (1600 - 300)));
}

h3 {
    font-size: calc(15px + (26 - 15) * ((100vw - 300px) / (1600 - 300)));
}

h4 {
    font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (1600 - 300)));
}

h5 {
    font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
}

h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.show {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.fl {
    float: left
}

.fr {
    float: right
}

.rel {
    position: relative
}

.textcenter {
    text-align: center
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.clear,
.clearfix {
    clear: both;
}

.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-family: 'avantgarde_bk_btdemi';
}

.required_star {
    color: #f00;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}

.slick-slider,
.slick-slider * {
    outline: none;
}

p {
    margin: 0 0 15px;
}

.title_sec {
    margin: 0 0 45px;
    color: #040507;
}

.title_sec h2 {
    font-size: 44px;
    line-height: 1;
    margin: 0 0 5px;
}

.title_sec small {
    font-size: 16px;
    display: block;
    font-weight: normal;
    margin: 0 0 13px;
}

.title1 {
    font-size: 32px;
    color: #040507;
    line-height: 1;
    margin: 0 0 24px;
}

.title3 {
    font-size: 20px;
    color: #040507;
    line-height: 1;
}

.title4 {
    font-size: 18px;
    color: #010101;
}

.icon-cap {
    background: url(../images/icon-cap.png) no-repeat;
    width: 34px;
    height: 31px;
    display: block;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}


.dropdown-menu>li>a {
    padding: 7px 20px;
    border-top: 1px solid #e9ebf5;
}

.dropdown-menu>li:first-child>a {
    border-top: 0;
}

.form_grouptt {
    font-size: 16px;
    margin: 0 0 20px 2px;
    color: #000;
    text-transform: capitalize;
}

.form-group {
    margin: 0 0 8px;
}

label {
    font-size: 14px;
    color: #959595;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}

.form-control {
    height: 48px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 0;
    color: #615f5f;
    width: 100%;
    display: block
}

textarea.form-control {
    height: 160px;
}

textarea.form-control.sm {
    height: 90px;
}

.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #000000;
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}

.clock_icon,
.ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img,
.ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.profile-info-div {
    margin: 35px 0;
}

.other-add {
    margin: 30px 0 0 0
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
    display: table;
    clear: both;
    content: ""
}

.acc-inform .form-group .row .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
    margin-right: 0;
}

.re_select input[type="text"] {
    margin: 0;
    height: 34px;
}

.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0;
    height: 48px;
}

.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}

.custom_select {
    position: relative;
}

.custom_select:after {
    content: "";
    background: #FCF5D5 url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 46px
}

.react-datepicker {
    font-family: 'Calibri' !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-weight: bold
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #8F0F33 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #8F0F33 !important;
}

.react-datepicker__header {
    background-color: #e5dbc9 !important;
}

.react-datepicker__navigation {
    margin: 0;
    box-shadow: none;
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{ border-radius: 50% !important;     background-color: #8f0f33 !important}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
	background-color: #8f0f33 !important;
}

.custom_checkbox {
    position: relative;
    display: inline-block;
    line-height: 25px;
}

.custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.custom_checkbox input[type=checkbox]:checked + span:before,
.cdd-details-lhs.fl .checkout-billing-address input[type=checkbox]:checked + span:before {
    content: "";
    text-rendering: auto;
    text-align: center;
    border-color: transparent;
    background: url(../images/tick-20.png) no-repeat;
    border: 0;
}

.custom_checkbox .disableactive + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: #ff8400;
    text-align: center;
    text-align: center;
    border-color: #ff8400;
}

.custom_checkbox span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 1px;
    pointer-events: none;
    line-height: 20px;
    border-radius: 50%;
        border: 1px solid #c3c3c3;
}

.custom_checkbox span {
    position: relative;
    padding: 0 0 0 25px;
    pointer-events: none;
    color: #3d3d3d;
    display: inline-block;
}

.custom_radio {
    position: relative;
    display: inline-block;
}

.custom_radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.custom_radio input[type=radio]:checked + span:before {
    background: url(../images/tick-20.png) no-repeat;
    z-index: 1;
    border: 0px;
}

.custom_radio span:before {
    content: "";
    /*background: #fff;*/
    background: #FCF5D5;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 1px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.custom_radio span {
    position: relative;
    padding: 0 0 0 30px;
    pointer-events: none;
    color: #353535;
    display: block;
}

.ckbox_custmze {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ckbox_custmze input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid #ecdfcc;
}

.ckbox_custmze:hover input ~ .checkmark {
    background-color: #fff;
}

.ckbox_custmze input:checked ~ .checkmark {
    background-color: #72624f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.ckbox_custmze input:checked ~ .checkmark:after {
    display: block;
}

.ckbox_custmze .checkmark:after {
    left: 10px;
    top: 5px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    line-height: 1.1;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 5px;
    position: absolute
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.vtop {
    vertical-align: top;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.tab_mobtrigger {
    display: none;
}

.main_tabsec {
    margin: 10px 0 40px;
}
.main_tabsec .mainacc_mobrow {background: #fcf5d5;padding: 10px;}
.main_tabsec > .nav-tabs {
    border: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-radius: 50px;
    margin: 0 0 20px;
}

.main_tabsec > .nav-tabs>li {
    margin: 0;
    display: table-cell;
    float: none;
    text-align: center;
    width: 50%;
    vertical-align: middle;
    border-left: 2px solid transparent;
}

.main_tabsec > .nav-tabs>li:first-child {
    border-left: 0;
}

.main_tabsec > .nav-tabs>li>a {
    background: #b69a6b;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 15px;
}

.main_tabsec > .nav>li>a:focus {
    background: none;
}

.main_tabsec > .nav-tabs>li.active>a,
.main_tabsec .nav-tabs>li.active>a:focus,
.main_tabsec .nav-tabs>li>a:hover {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec > .nav-tabs>li>a:after {
    content: "";
    border: 18px solid #0b0405;
    border-color: #0b0405 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -36px;
    margin-left: -18px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.main_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}

.main_tabin {
    border-radius: 4px;
}

.filter_tabsec {
    margin: 0 0 40px;
}

.filter_tabsec .nav-tabs {
    margin: 0 0 30px;
}

.filter_tabsec .nav-tabs>li {
    width: 17%;
    margin: 0;
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: middle;
}

.filter_tabsec .nav-tabs>li:first-child {
    border-left: 0;
}

.filter_tabsec .nav-tabs>li>a {
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #272626;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 0 0 5px;
    display: block;
    font-family: 'avantgarde_bk_btdemi';
}

.filter_tabsec .nav>li>a:focus {
    background: none;
}

.filter_tabsec .nav-tabs>li:hover>a,
.filter_tabsec .nav-tabs>li.active>a,
.filter_tabsec .nav-tabs>li.active>a:focus,
.filter_tabsec .nav-tabs>li.active>a:hover {
    border: 0;
    background: none;
    color: #060d15;
}

.filter_tabsec .nav-tabs>li>a:after {
    content: "";
    background: #ffdf00;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.filter_tabsec .nav-tabs>li:hover>a:after,
.filter_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
    border:1px solid #b79a6c
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
    background: none;
}

.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 45px;
    height: 22px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 1px;
}

.tgl + .tgl-btn:before {
    display: none;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

ul.tg-list {
    float: left;
    padding-top: 7px;
    list-style: none;
    padding-left: 0;
}

.tgl-light + .tgl-btn {
    background: #f9f9f9;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #b4b4b4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 22px;
    height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
    background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
    display: none
}

.mobile_hmenu_list .submenu-arow {
    display: block;
    width: 40px;
    background: #e6e6e6;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin-top: -11px;
    font-size: 22px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec, .hsearch_sec_mbl {
    width: 380px;
    position: absolute;
    right:0px;
    top: 40px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    visibility: hidden;
}

.hsearch_sec.open, .hsearch_sec_mbl.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}

.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}

.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-block;
    position: relative
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    list-style: none;
    position: relative;
}

.hordertype_sec a {
    display: block;
    padding: 15px 22px;
    color: #fff;
    background: #8F0F33;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    position: relative;
    letter-spacing: 0.5px;
    margin: 0 15px 0 25px;
    line-height: 1;
    border-radius: 5px;
}

.hordertype_sec a:hover {
    color: #000;
    background: #ffdf00;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #fff;
    z-index: 99;
    width: 220px;
    position: absolute;
    left: 0px;
    top: 33px;
    display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
    font-family: 'avantgarde_bk_btdemi';
    font-size: 16px;
    color: #1b0000;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #ab2225;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;
    font-family: 'avantgarde_bk_btdemi';
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
    color: #ffa700;
}

.hsign_sec > a {
    display: inline-block;
    position: relative;
}

.hsign_sec > a:hover {
    color: #000;
}

.hsearch_sec .search_result, .hsearch_sec_mbl .search_result_mbl, .product_search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none;
}
.product_search_result{position: absolute; top: 100%; left: 0;z-index: 5;width: 100%;}

.search_result li, .search_result_mbl li, .product_search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found, .search_result_mbl li a, .product_search_result li a {
    color: #333;
    display: block;
    padding: 9px 15px
}

.search_result li a:hover, .search_result_mbl li a:hover, .product_search_result li a:hover {
    background: #6e1d24;
    color: #fff;
}

.hsearch_sec.open .input-sec, .hsearch_sec_mbl.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input  {
    padding-right: 10px;
    margin-bottom: 0;
    border-color: rgba(0, 0, 0, 0.3);;
}
.htico_search{
    position: relative;
}
.htico_search a {
    background: #fff;
    border: 1px solid #DADADA;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_text_clear {
    position: absolute;
    right: 10px;
    top: 11px;
    display: none
}

.htico_cart {
    position: relative;
    margin-left: 10px;
    padding-left: 5px;
}

.htico_cart > a {
    position: relative;
    background: #fff;
    border: 1px solid #DADADA;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}
header .hcart_dropdown { display: none}
/* 

header .hcart_dropdown {
    background: #fff;
    width: 100%;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    z-index: 11;
    max-width: 620px;
    padding: 45px 0 50px 0;
}
header .hcart_dropdown.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}*/
body.cart-items-open{
    overflow: hidden;
}
body.cart-items-open .logo-main-section:before {
    position: fixed;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.68);
    z-index: 9;
}
.cart_info {
    display: inline-block;
    vertical-align: top;
    color: #3d3d3d;
}

.cart_info h4 {
    font-size: 14px;
    margin: 0 0 6px;
    color: #161616;
}

.cart_left .help-block {
    font-style: italic;
    color: #a7a4a4;
    margin: 5px 0 0;
    font-size: 14px;
}

.cart_extrainfo {
    color: #3d3d3d;
}

.cart_extrainfo p b {
    color: #959595;
    font-style: italic;
    font-weight:normal;
     font-size: 13px;
}

.cart_extrainfo p, .notes-block {
    margin: 0;
    color: #959595;
    font-size: 13px;
    padding-left: 10px
}

.cart_price {
    position: relative;
    margin: 0;
    padding-right: 17px;
}

.cart_price p {
    margin: 0;
    color: #161616;
    font-size: 16px;
    font-family: 'avantgarde_bk_btdemi';
}

.cart_remove {
    background: #302d2a;
    position: absolute;
    top: 14px;
    right: 15px;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_remove:hover {
    background-color: #000;
}
.cart_remove img{
    width: 8px;
}

.qty_bx {
    width: 86px;
    height: 30px;
    position: relative;
    padding: 0 24px;
    display: inline-block;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
}

.qty_bx span {
    position: absolute;
    width: 24px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #FCF5D5;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.qty_bx span.qty_minus {
    left: 0px;
}

.qty_bx span.qty_plus {
    right: 0px;
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #000;
    font-size: 13px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    border-radius: 0px;
    font-weight: bold;
    border-right: 2px solid #e0e0e0;
    border-left: 2px solid #e0e0e0;
}

.qty_bx span:after {
    position: absolute;
    width: 12px;
    height: 12px;
    display: block;
    top: 49%;
    margin-top: -5px;
    content: "";
}

.qty_bx span.qty_minus:after {
    background: url(../images/minus.svg) no-repeat;
    height: 12px;
    margin-top: -1px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.qty_bx span.qty_plus:after {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: url(../images/plus.svg) no-repeat;
}
.cart_footer span {
    font-size: 16px;
}
.cart_footer .cart_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.cart_footer .cart_row p{margin: 0;font-family: 'Helvetica';text-transform: capitalize;color: #353535;}

.discount_info {
    margin-left: 15px;
}

.discount_info p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #3d3d3d;
}

.promo_delico_parent {
    position: relative;
}

.promo_delico {
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
}

.promo_delico:hover {
    background-color: #b69a6b;
    color: #fff;
}

.cart_footer_totrow p {
    font-size: 25px;
}

.cart_footer_totrow p.help-block {
    font-size: 12px;
    font-size: 14px;
    font-weight: 300;
}

.cart_footer_totrow span {
    font-size: 25px;
}

.indication_progress {
    height: 12px;
    overflow: hidden;
    border-radius: 10px;
    background: #ffa700;
    position: relative
}

.progress_bar {
    content: "";
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background:#f7d3bd;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.indication .help-block {
    color: #cdaf9d;
    font-size: 14px;
    font-weight: 400;
    margin: 7px 0 0;
}

.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}

.delivery_address {
    margin: 0 0 20px;
}

.delivery_info h4 {
    color: #838181;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.delivery_info p {
    color: #3d3d3d;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}

.delivery_link {
    color: #3d3d3d;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0;
    display: inline-block;
    vertical-align: top;
}

.delivery_link:hover {
    border-color: #b69a6b;
}

.delivery_time {
    margin: 0 0 16px;
}

.delivery_handle {
    border-top: 1px solid #d3d1d1;
    padding: 20px 0 0 0
}

.cart_action .btn_grey {
    margin: 0;
    border-color: #e6e3e3;
}

.download_btn i {
    margin-right: 9px;
    vertical-align: top;
    display: inline-block;
}

.ico_download {
    width: 24px;
    height: 22px;
    background: url(../images/download.png) no-repeat;
}

.download_btn:hover .ico_download {
    background-image: url(../images/download1.png);
}

.cartaction_price {
    font-size: 24px;
    width: 30%;
    cursor: default;
}

.cartaction_price sup {
    font-size: 50%;
    left: -4px;
    top: -7px;
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

header .cart_action{  padding: 20px 20px 0 20px}

.header_top {
    background: #181818;
    position: relative;
}

.htopinfo {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 10px 0;
}

.htopinfo p {
    margin: 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.header_bot {
    position: relative;
}

.sticky .alert_fixed {
    top: 95px;
}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

.viewmore_poplink {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.hbslider_sec h2 {
    font-size: 24px;
    color: #363636;
}

.hbslider_sec .slick-dots {
    position: absolute;
    bottom: 15px;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-dots li, .home-outlets-list .slick-slider .slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

.hbslider_sec .slick-dots li button, .home-outlets-list .slick-slider .slick-dots li button {
    background: none;
    border: 1px solid #fff;
    padding: 0;
    border-radius: 50%;
    font-size: 0;
    width: 12px;
    height: 12px;
}

.hbslider_sec .slick-dots li.slick-active button { background: #000;}
.home-outlets-list .slick-slider .slick-dots li button{ background: #000; border: 1px solid #000}
.home-outlets-list .slick-dots li.slick-active button{ background:#ffa700; border: 1px solid #ffa700 }

.hbslider_sec .slick-arrow {
    z-index: 1;
}

.hbslider_sec .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hbslider_sec .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -188px -60px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-next:hover {
    background: #f5f5f5;
}

.hbslider_sec .slick-next:hover::after {
    background-position: -78px -60px;
}

.hbslider_sec .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hbslider_sec .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -136px -61px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-prev:hover {
    background: #f5f5f5;
}

.hbslider_sec .slick-prev:hover::after {
    background-position: -26px -61px;
}

.white-popup .hbslider_sec .hbslide {
    text-align: center;
}

.hme-product-inner {
    padding: 50px 50px;
    background: #fff;
    margin: 25px 0 0 0;
}

.hme-product-inner .filter_tabsec .nav-tabs {
    margin-bottom: 64px;
    border-bottom: 0;
}

.hme-product-inner .title_sec {
    margin-bottom: 25px;
}

.hme-product-inner .ttsplitter {
    margin-bottom: 25px;
}

.hme-prt-row .slick-slider {
    padding: 0 90px;
}

.hme-prt-column {
    position: relative;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.hme-product-inner .slick-slide {
    padding: 0 10px;
    outline: 0;
}

.hme-product-inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hme-product-inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -78px -60px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hme-product-inner .slick-next:hover {
    background: #f5f5f5;
}

.hme-product-inner .slick-next:hover::after {
    background-position: -188px -60px;
}

.hme-product-inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hme-product-inner .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -26px -61px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hme-product-inner .slick-prev:hover {
    background: #f5f5f5;
}

.hme-product-inner .slick-prev:hover::after {
    background-position: -136px -61px;
}

.hme-prt-column {
    position: relative;
}

.hme-prt-innerslide {
    position: relative;
}

.hme-prt-inner {
    position: relative;
}

.hme-prt-slidecontent {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: rgba(255, 255, 255, 0.86);
    opacity: 0;
    padding: 15px;
}

.hme-prt-hei {
    text-align: center;
}

.hme-prt-hei img {
    max-height: 100%;
    width: auto;
    height: auto;
}

.hme-prt-column:hover .hme-prt-slidecontent {
    opacity: 1;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.hme-prt-slidecontent-inner {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: relative;
    text-align: center;
}

.hme-prt-slidecontent-inner h3 {
    font-size: 19px;
    margin-bottom: 3px;
    color: #556c11;
    height: 27px;
    overflow: hidden;
}

.hme-prt-slidecontent-inner span {
    font-size: 19px;
    color: #556c11;
}

.hme-prt-slidecontent-inner p {
    color: #556c11;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 23px;
    height: 40px;
    overflow: hidden;
    padding: 0 10px;
}

.outlet {
    position: relative;
}

.outlet_inner {
    padding: 63px 35px 76px 35px;
    background: #fff;
    margin: 25px 0 39px 0;
}

.innerproduct > h3 {
    font-size: 19px;
    text-transform: capitalize;
}

.innerproduct-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.addcart_row {
    display: none;
    padding: 0 5px;
    margin: 0 auto;
}
.addcart-row-child{     display: -webkit-box;
    display: -ms-flexbox;
    display: flex;  justify-content: center;    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;}

.modal-lg {
    width:100% ;
    max-width:740px;
    margin: 0 auto;
}
.inn-product-popup {
    background: #fff;
    width: 100%;
}

.inn-product-popup .container {
    padding: 0;
}
.prodet_banleft {
    width: 100%;
}

.prodet_baninfo {
    width: 100%;
    padding:30px 0 0 0;
    position: relative;
    text-align: center;
}

.prodet_baninfo .product-tags-list {
    position: relative;
    margin: 0 0 20px 0
}

.prodet_baninfo .product-tags-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap;
    justify-content: center;
}
.prodet_baninfo .product-tags-list ul li{margin: 0 5px}

.inn-product-popup-bg {
    position: relative;
}

.inn-product-popup-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 345px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.inn-product-popup-inner {
    position: relative;
    padding-top: 0px;
    line-height: 1;
}

.inner_product_slide {
    outline: 0;
}

.inn-product-popup-inner .slick-next,
.inn-product-popup-inner .slick-prev {
    display: none !important
}

.inn-product-popup-inner .custom-dots {
    padding: 0;
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
}

.inn-product-popup-inner .custom-dots li {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
}

.inn-product-popup-inner .custom-dots li button {
    padding: 0;
    margin: 0
}

.inn-product-popup-inner .custom-dots li button:before {
    content: "";
    background: #fff;
    width: 12px;
    height: 12px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
}

.inn-product-popup-inner .custom-dots li.slick-active button:before {
    background: #ffa700;
}

.inn-product-popup-inner .custom-dots li button {
    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
}

.inner_product_slide {
    position: relative;
}

.pthumb_slide {
    margin: 5px;
    border: 1px solid #a2a6ac;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
    -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    border-color: #e77600;
}

.popup_social {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    right: 0;
    right: 5px;
}

.popup_social span {
    color: #373535;
    font-size: 12px;
    font-weight: 700;
}

.popup_social_icon {
    list-style: none;
    margin-bottom: 0;
    background: #fff;
    padding: 4px 0px 4px 4px;
}

.popup_social_icon li {
    float: left;
}

.popup_social_icon li a {
    color: #2a2a2a;
    padding: 0 10px;
    display: inline-block;
}

.popup_social_icon li a:hover {
    color: #ccc;
}

.popup_social_inner {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup_social_left {
    padding-right: 10px;
}

.copied {
    position: absolute;
    right: 10px;
    top: -21px;
    background: #000;
    padding: 3px 7px;
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    border-radius: 2px;
    pointer-events: none;
}

.copied:before {
    content: "";
    border: 7px solid #000;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -11px;
    margin-left: -7px;
}

.inn_product_row {
    margin: 0 auto 0 auto;
}

.inn_product_hea p {
    color: #5b5b5b;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 20px;
}

.inn_product_hea_left h3 {
    font-size: 34px;
    margin: 0 0 10px 0;
    color: #050505;
    text-transform: capitalize;
}

.prd_chosen_row {
    padding: 30px 30px 30px 30px;
}

.product_chosen_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product_chosen_col {
    width: 100%;
}

.product_chosen_col.product_chosen_col_right {
    margin-right: 0;
}

.product_chosen_col_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
        margin-bottom: 30px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
    margin-bottom: 0;
}

.form-group-modifier {
    margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
    width: 100%;
    margin-bottom: 10px
}

.product_chosen_item_left:nth-of-type(2n+2) {
    margin-right: 0;
}

.product_chosen_item_left_second {
    float: left;
    width: 47%;
    margin-right: 3.9285714285714285%;
}

.product_chosen_item_left_second:nth-of-type(2n+2) {
    margin-right: 0;
}

.select_full {
    width: 100%;
}

.product_chosen_hea h6 {
    font-size: 17px;
    color: #000000;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}

.product_chosen_hea {
    position: relative;
}

.product_chosen_item_left .chosen-container-single .chosen-single {
    width: 100%;
    height: 47px;
    line-height: 40px;
    border: 1px solid #e6e5e5;
    font-weight: 700;
}

.product_chosen_item_left .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -246px -62px;
    width: 28px;
    height: 28px;
}

.product_chosen_item_left .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 28px;
    height: 28px;
    background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
    top: 10px;
    right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.pmodifier_ddlist ul {
    padding: 0;
    margin: 7px 0;
    list-style: none;
}

.pmodifier_ddlist li {
    background: #ecdfcc;
    padding: 3px 35px 3px 17px;
    border: 1px solid #dcd8d8;
    border-radius: 20px;
    position: relative;
    font-weight: 700;
    color: #181818;
    margin: 0 0 4px;
}

.pmodifier_close {
    background: url(../images/close-modifier.png) no-repeat center;
    width: 48px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 20px 20px 0;
    margin: auto 0;
}

.pmodifier_close:hover {
    background-image: url(../images/cross1.png);
    background-color: #1c1c1c;
}

.product_chosen_addons {
    width: 100%;
}

.chosen_adn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.chosen_adn_right {
    line-height: 0;
}

.chosen_adn_left span {
    font-size: 16px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}

.radio.chosen_adn_right label {
    padding-left: 0;
}

.radio .qty_bx label {
    font-weight: 700;
}

.product_chosen_col_right .custom_checkbox {
    width: 100%;
}

.product_chosen_col_right .custom_checkbox span {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    display: block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 30px;
    padding-left: 0;
}

.product_chosen_col_right .custom_checkbox span:before {
    right: 0;
    left: auto;
    top: -5px;
}

.product_chosen_col_right .chosen_adn {
    margin-bottom: 16px;
}

.text-box {
    width: 100%;
}

.text-box em {
    text-transform: uppercase;
    font-size: 13px;
    color: #181818;
    text-align: right;
    float: right;
}

.text-box textarea {
    width: 100%;
    height: 120px;
    padding: 15px 15px;
}

textarea {
    resize: none
}

.chosen_upload {
    float: right;
    text-align: right;
    width: 100%;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: 0;
}

.inputfile + label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
}

.inputfile + label:hover {
    background: #333;
}

.inputfile + label:hover span {
    color: #fff;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.chosen_upload_inner label span {
    color: #72624f;
}

.inputfile-4 + label {
    color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #722040;
}

.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #722040;
}

.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: 722040;
}

.prd_chosen_sub_row {
    text-align: center;
        padding: 0 30px 20px;
}

.prd_chosen_sub_row > p {
    font-size: 35px;
    color: #000;
    font-family: 'avantgarde_bk_btdemi';
   margin-bottom: 5px;
}

.prd_chosen_sub_row p > sup {
    font-size: 20px;
}

.prd_chosen_sub_col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 576px;
    margin: 0 auto;
}
.popup_addcart_cls{max-width: 100%}

.prd_chosen_sub_col .alert {
    margin: 0;
    width: 100%;
}

.prd_chosen_sub_item_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.prd_chosen_sub_item_right {
    /*width: 67.64705882352942%;*/
    width: 100%;
}

.prd_chosen_sub_item_right button {
    border: 0;
    padding: 15px 15px;
    width: 100%;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    outline: 0;
    font-family: 'avantgarde_bk_btdemi';
    margin-bottom: 0;
    border-radius: 0;
        
}

.prd_chosen_sub_item_right button:hover {
    background: #000000;
}

.prd_chosen_sub_item_right.prd_chosen_item_full {
    width: 100%;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button {
    max-width: 100%;
    margin: 0;
    letter-spacing: 0.5px;
    font-size: 18px;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button:hover {
    background: #333;
}

.prd_chosen_sub_item_left .qty_bx {
    width: 100px !important;
}

.prd_chosen_sub_item_left .qty_bx .qty_minus {
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd_chosen_sub_item_left .qty_bx .qty_plus {
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.skip {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    background: #dbd9d9;
    max-width: 89px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 7px 15px;
    color: #181818;
}

.skip:hover {
    color: #fff;
    background: #333;
}

.prd-sub-bg {
    background: #fff;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 10px 0 10px 10px;
    -webkit-border-radius: 10px 0 10px 10px;
    box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    position: relative;
}

.prd-sub {
    max-width: 862px;
    position: relative;
    margin: 0 auto;
}

.prd-sub h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}

.prd-sub .slick-slide {
    padding: 0 5px;
}

.prd-sub .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-next:hover {
    background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
    background-position: -154px -123px;
}

.prd-sub .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-prev:hover {
    background: #f5f5f5;
}

.prd-sub .slick-prev:hover::after {
    background-position: -213px -123px;
}

.prd-sub-slide-content {
    text-align: center;
    margin-top: 10px;
}

.prd-sub-slide-content h5 {
    font-size: 14px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
    background: #f4b56b;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
    background: #e48f2c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
    background: #333;
}

.prd-sub-slide-qty .qty_bx input {
    background: #fff;
    width: 62px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
    background: #ecdfcc;
    padding: 4px 0 0px 0;
    border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
    width: 146px;
    height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
    height: 40px;
}

.prd-sub-slide-submit {
    background: #181818;
}

.prd-sub-slide-submit a {
    font-size: 18px;
    padding: 13px 0;
    display: block;
    text-transform: uppercase;
    color: #fff;
    border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
    background: #e48f2c;
}

.slick-slide {
    outline: 0;
}

.prd-sub-slide-content .btn {
    width: 100%;
}

.checkout_hea {
    position: relative;
}

.checkout_hea::after,
.check_pg::after {
    display: block;
    content: "";
    clear: both;
}

.checkout_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.checkout_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.checkout_hea_inner {
    margin-top: 8px;
}

.check_pg {
    position: relative;
}

.check_pg_inner {
    background: #fff;
    padding: 75px 0px 29px 0px;
    margin-bottom: 14px;
}

.check-pg-slider .slick-slider {
    padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
    width: auto;
    margin-right: 0;
}

.check_pg_inner .slick-slide img {
    text-align: center;
    margin: auto;
}

.check_pg_inner .slick-slide {
    padding: 0 7.5px;
}

.check_pg_inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner .slick-next:hover {
    background: #f5f5f5;
}

.check_pg_inner .slick-next:hover::after {
    background-position: -154px -123px;
}

.check_pg_inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner .slick-prev:hover {
    background: #f5f5f5;
}

.check_pg_inner .slick-prev:hover::after {
    background-position: -213px -123px;
}

.checkoutpage_form_outer .form-group {
    margin-bottom: 15px;
}

.check_out_fourcommon {
    width: 100%;
    margin: 0 auto;
}

.checkoutpage_form_outer {
    width: 100%;
    margin: 0 auto;
}

.cdd-details{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin-bottom: 20px; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;}
.cdd-details .ttsplitter{margin-bottom: 0;}
.checkout-total {width: 100%;margin: 25px auto 0px auto}
.checkout-total::after {display: block;content: "";clear: both}
.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {padding: 14px 20px;position: relative;background: #87311d;border-bottom: 0px;text-align: center;font-size: 24px;width: 100%;margin-bottom: 0}
.checkout-total .cart-header {padding: 0;background: inherit;border: 0;margin: 0}
.checkout-total .cdd-details-rhs .cart-header h4 {margin: 0;line-height: normal}
.checkout-total .cart-header:after {display: none}
.checkout-total .cdd-details-lhs {width:49%;position: relative;padding: 0;/*background: #fff;*/ background: #FCF5D5;  -webkit-box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18); box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18);}
.checkout-total .cdd-details-rhs {width:49%;padding:0;/*background: #fff;*/ background: #FCF5D5; position: relative;-webkit-box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18);box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18);}
.checkout-total .cdd-details-rhs .chekout_cart_bar {width: 100%;height: 100%;position: relative;display: flex;flex-direction: column;}
.checkout-right-body-section {padding: 10px 15px 20px 15px;height: 100%;}    
.checkout-total .cdd-details-rhs .checkout-right-body-section .cart_table {height: 100%;display: flex;flex-direction: column;}
.checkout-total .cdd-details-rhs .checkout-right-body-section .cart_table .cart_footer {flex-grow: 1;display: flex;align-items: flex-end;}
.checkout-total .cdd-details-rhs .checkout-right-body-section .cart_table .cart_footer .cart_footer_inner_item {width: 100%;}
.checkout-body-section{ padding: 20px}


.chk_hea {
    font-size: 16px;
    padding: 0px 0px 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 10px 0;
    line-height: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
    color: #464646;
    position: relative;
}

.chk_hea > span {
    color: #000000;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    padding-right: 30px;
}

.checkout-total .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.checkout-total .checkout-control-group-top a {
    font-size: 14px;
    color: #000;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0 0px;
    display: inline-block;
    vertical-align: top;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.checkout-total .checkout-control-group-top a:hover {
    color: #ffa700
}

.checkout-total .checkout-control-group-top {
    padding: 0px 0 25px 0;
    border-bottom: 1px solid #eeeded;
}

.checkout-control-group-topband {
    border-bottom: 1px solid #939292;
    padding: 0 0 35px 0
}

.checkout-control-group-topband span {
    float: right;
    font-weight: normal;
    font-size: 15px
}

.checkout-total .checkout-control-group-middle {
    margin: 0px 0 25px 0;
    padding: 25px 0 20px 0;
    width: 100%;
    border-bottom: 1px solid #eeeded;
}

.checkout-total .checkout-control-group-bottom {
    padding: 25px 0 0 0;
}
.checkout-billing-address {
    padding: 25px 0 20px 0;
    width: 100%;
    border-bottom: 1px solid #eeeded;
}
.check-billing{ padding: 20px 0 0 0; }
.checkout-billing-address .chk_hea{padding-bottom: 0; margin-bottom: 0}
.checkout-total .checkout-control-group-middle .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.checkout-total .checkout-control-group-middle .form-group .react_datepicker {
    width: 48%
}

.checkout-total .checkout-control-group-middle .form-group .input_area {
    width: 49%
}

.checkout-total .checkout-control-group-bottom textarea {
    padding: 10px 15px;
    height: 100px;
    font-size: 15px;
    width: 100%;
    outline: 0;
}

.checkout-total textarea::-webkit-input-placeholder {
    color: #595656;
}

.checkout-total textarea:-moz-placeholder {
    color: #595656;
}

.checkout-total textarea::-moz-placeholder {
    color: #595656;
}

.checkout-total .cart_body {
    padding: 0;
    box-shadow: none;
}

.checkout-total .form-group.controls-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.checkout-total .form-group.controls-three > div {
    width: 49%;
}

.checkout-total .form-group.controls-three > div input {
    margin-bottom: 0
}

.order-detail-inner {
    padding: 82px 0 0 0
}

.order-detail-inner-icon {
    position: relative;
    max-width: 604px;
}

.order-detail-inner-icon-left {
    position: absolute;
    left: 0;
    top: 2px;
}

.order-detail-scotter {
    position: relative;
    z-index: 2;
}

.order-detail-scotter::after {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -283px -126px;
    width: 41px;
    height: 24px;
    left: 0;
    top: -2px;
    position: absolute;
}

.order-detail-scotter::before {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -347px -126px;
    width: 22px;
    height: 22px;
    right: 0;
    top: -2px;
    position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
    position: absolute;
    right: 0;
    top: 3px;
    left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
    position: static;
    left: inherit;
    top: inherit;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
    display: block;
    clear: both;
    content: "";
}

.order-detail-inner-icon-left.order-detail-inner-center::before {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    max-width: 497px;
    left: 0;
    right: 0;
    height: 10px;
    margin: auto;
    top: 50%;
}

.order-detail-inner-icon-left.order-detail-inner-center img {
    vertical-align: middle
}

.delivery_total::after {
    display: block;
    content: "";
    clear: both;
}

.delivery_total {
    margin: 10px 0 0px 0;
}

.delivery_total_left {
    float: left;
    text-align: left;
    width: 50%;
}

.delivery_total_left h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
    color: #1c1b1b;
    line-height: 22px;
    font-size: 17px
}

.delivery_total_left span {
    display: block;
    color: #000;
    line-height: 20px;
    font-size: 14px
}

.delivery_total_left.delivery_total_right {
    float: right;
    text-align: right
}

.delivery_total.delivery_total_number {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.delivery_total.delivery_total_number .delivery_total_left h2 {
    font-size: 16px;
    color: #3d3d3d;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.delivery_total.delivery_total_number .delivery_total_left h4 {
    color: #060203;
    font-size: 27px;
    margin-bottom: 0;
}

.pickup-order-div:before {
    display: none
}

.pickup-order-div .delivery_total_left {
    width: 100%;
    text-align: center
}

.pickup-order-div .delivery_total_left img {
    width: 25px;
}

.cart-product-desc-inner {
    padding-left: 20px
}

.cart-product-desc-inner span {
    display: block;
    color: #3d3d3d;
    line-height: 19px;
    font-size: 14px
}

.chekout_cart_bar .cart_footer {
    padding: 28px 15px 10px 15px;
    margin: 0
}

.chekout_cart_bar .cart_left {
    padding-left: 0;
}

.chk_hea .custom_checkbox {
  position: absolute;
    top: -2px;
    right: 0;
}
.chk_hea .custom_checkbox span{ vertical-align: top;}

.chk-payment-btn-row {
    margin: 10px 0 0 0
}

.chk-payment-btn-row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 16px;
    padding: 16px 10px;
    font-family: 'avantgarde_bk_btdemi';
}

.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls,
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls {
    width: 49%
}

.dwn_quotion {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.dwn_quotion a {
    background: #e6e3e3;
    font-size: 16px;
    font-weight: 700;
    display: block;
    padding: 10px 0;
    color: #0d0d0d;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dwn_quotion a:hover {
    background: #333;
    color: #fff;
}

.redeem {
    position: relative;
    margin-bottom: 20px;
}

.redeem::after {
    display: block;
    clear: both;
    content: "";
}

.redeem-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.redeem-col {
    width: 49%;
    background: #8f0f33;
    padding: 40px 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}
.redeem-col-full{width: 100%}
.redeem-col-full .redeem-item{   max-width: 660px; margin: 0 auto;}
.redeem-col.redeem-col-right {  background: #8f0f33;}

.redeem-item-hea {
    padding-left: 10px;
    position: relative;
}

.redeem-item-hea h4 {
    font-size: 27px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 0;
}
.redeem-item-hea span {
    font-size: 15px;
    color: #fff;
}
.redeem-col:first-of-type .redeem-item-hea h4, .redeem-col:first-of-type .redeem-item-hea span{ color: #fff}

.redeem-item-hea .points {
    position: absolute;
    right: 0;
    background: #1c1c1c;
    padding: 8px 2px;
    max-width: 260px;
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    top: 8px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    font-size: 13px;
}

.redeem-item-hea .points b {
    color: #f4e329;
    font-weight:bold;
}
.redeem-col:first-of-type .redeem-item-hea .points b{  color: #54abd4; }

.redeem_apply {
    position: relative;
    margin-top: 5px;
}

.redeem_apply .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    margin: 0;
   background: #000;
    padding: 16px 22px;
    border-radius: 0 5px 5px 0;
    font-size: 16px;
}

.redeem_apply .btn:hover {
    background: #ffdf00;
    color: #000;
}
.redeem-item-hea-inner {
    width: 41.37770897832817%;
}

.chk-payment {
    padding: 40px 0px 30px 0px;
    text-align: center;
    background: #fff;
}

.chk-payment-col-radio {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.chk-payment-col-radio li {
    max-width: 257px;
    margin-right: 10px;
    padding: 15px 20px 15px 20px;
    border: 1px solid #d0cfcb;
    border-radius: 7px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chk-payment .chk-payment-col-radio li .radio_con{  font-size: 16px; text-transform: uppercase;    font-family: 'avantgarde_bk_btdemi';}
.chk-payment .chk-payment-col-radio li label { padding-left: 35px !important;}

/* 
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

*/
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    margin-top: -19px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #d0cfcb;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/tick-20.png) no-repeat;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:after {
    width: 28px;
    height: 28px;
    background-position: -455px -122px;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:before {
    width: 28px;
    height: 28px;
}

.chk-payment-col-radio.promotion-ul-topcls li {
    width: auto;
    margin-right: 0
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    top: 0px;
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    top: 0px;
}

.chosen_radio_inner {
    width: 100%;
}

.expand {
    background: #eaeaea;
    padding: 10px 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}

.expand h3 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    width: 100%;
}

.expand::after {
    content: "\f107";
    right: 10px;
    display: block;
    top: 3px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand.active::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.expand_sub h1 {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 10px;
}

.expand_sub h1 span {
    width: 80%;
}

.panel-heading.expand_hea {
    cursor: pointer;
}

.expand_sub h4.panel-title {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}
.expand_sub h4.panel-title:hover {color: #8F0F33;}
h4.panel-title:hover a {color: #8F0F33;}
.expand_sub_body {
    width: 100%;
}

.expand_sub h4::after {
    content: "\f107";
    right: 0;
    display: block;
    top: -7px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand_hea.active h4.panel-title::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chk-payment-btn {
    margin-bottom: 34px;
}

.chk-payment-btn-row .btn {
    width: 100%;
    border: 0;
}

.chk-payment-btn-row .row {
    margin-right: 0;
    margin-left: 0;
}

.chk-payment-btn-row [class*="col-"] {
    padding: 0;
}

.tnk-you {
    position: relative;
    padding: 110px 0;
}

.tnk-you::after {
    display: block;
    clear: both;
    content: "";
}

.tnk-header {
    max-width: 683px;
    margin: 0 auto;
    text-align: center;
}

.tnk-header .tick {
    padding: 0 15px;
}

.tick h2 {
    font-size: calc(17px + (44 - 17) * ((100vw - 300px) / (1600 - 300)));
    margin: 18px 0 0 0;
    color: #fff;
    text-transform: uppercase;
}

.tick span {
    font-size: 18px;
    color: #0b0405;
}

.tnk-detail {
    margin-top: 20px;
}

.tnk-detail h2 {
    padding: 13px 10px;
    color: #464646;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 0;
    font-family: 'avantgarde_bk_btdemi';
    font-size: 19px;
}
.Viewreceipt .tnk-detail h2{    
    color: #fff;
    background: #060203;
    font-size: 17px;
}

.tnk-order {
    background: #8f0f33;
    padding: 30px 15px;
}

.tnk-order h3 {
    margin-bottom: 10px;
    font-size: 36px;
    text-transform: uppercase;
    color: #fff;
}
.tnk-order h4 {color: #fff;margin-bottom: 10px;}
.tnk-order p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    font-family: 'avantgarde_bk_btdemi';
}
.Viewreceipt .tnk-order{ background: #f5f5f5;  }
.Viewreceipt .tnk-order h3 {
    color: #292929;
}


.Viewreceipt .tnk-order p {
    color: #292929;
    font-family: 'avantgarde_bk_btbook';
}

.tnk-delivery .order-detail-inner {
    padding-top: 0px;
}
.tnk-delivery {
    padding: 30px 15px 15px;
    background: #fff;
}

.cart_row.tnkorder-first {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cart_row.tnkorder-first > div {
    width: 49%;
}
.cart_row.tnkorder-first > div h5{
    margin-bottom: 5px;
    font-size: 16px;
}
.cart_row.tnkorder-first > div p{
    margin: 0;
    font-size: 15px;
}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {
    display: none;
}

.tnk-delivery .order-detail-inner-icon {
    margin: 0 auto;
}

.tnk-delivery .delivery_total {
        margin: 6px 0 20px 0 !important;
}
.tnk-chk-order {
    margin: 15px auto;
    max-width: 580px;
}

.tnk-delivery .cart_left .help-block {
    text-align: left;
}

.tnk-delivery .cart_info h4 {
    text-align: left;
}

.tnk-delivery .cart_extrainfo {
    text-align: left;
}

.tnk-delivery .cart_footer.tnk_cart_footer {
    padding: 20px 15px 0px 15px;
    margin: 0;
}

.tnk-delivery .tnk_cart_footer .cart_row {
    text-align: left;
    padding: 0px 0px 0px 0px;
}
.tnk-delivery .tnk_cart_footer .cart_row.grant-total-cls{     margin-bottom: 0;    padding: 10px 0px 10px 0px;    border-bottom: 3px solid #f4f4f5;}
.tnk-chk-order .btn {
    width: 100%;
    border: 0;
}

.delivery_datetime_div {
    padding: 10px 0 10px 0 !important;
    margin: 10px 0 0px 0 !important;
}

.remark_notesec {
    margin: 20px 0 0;
    padding: 0 7px;
}

.remark_notesec h4 {
    margin: 0 0 3px;
    font-weight: 700;
    color: #3d3d3d;
    font-size: 16px;
}

.remark_notesec p {
    margin: 0;
}

.mfp-bg {
    z-index: 10042;
}

.mfp-wrap {
    z-index: 10043;
}

.white-popup {
    max-width: 1026px;
    padding: 0;
    margin: 30px auto;
}
.order_popup{ max-width: 580px }
.pouup_in {
    padding: 60px 50px 40px;
}

.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup {
    max-width: 450px;
}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close,
button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_popup .mfp-close {
    display: none;
}

.alert_header {
    background: #181818;
    color: #fff;
    font-size: 20px;
    padding: 12px 14px;
    position: relative;
    text-align: center;
        font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase
}

.alert_body {
    text-align: center;
    padding: 20px;
}

.alt_btns a {
    margin: 0 5px;
}

.alt_btns a.button-left {
    background: #282828
}

.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    min-height: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop_title {
    position: relative;
    color: #fff;
}

.pop_title h2 {
    font-size: 36px;
    margin: 0;
    line-height: 1;
    color: #fff;
    text-transform: inherit
}

.pop_title small {
    font-size: 18px;
    font-weight: normal;
}

.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.innervmid_in {
    width: 100%
}

.delevery_popup .popup_ttsec {
    background: #8F0F33;
}

.delevery_popup .popup_ttsec {
    padding-left: 38px;
    padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
    border: 1px solid #dadada;
}

.two-button-row {
        display: flex;
    max-width: 476px;
    justify-content: space-between;
    margin: 0 auto;
}

.two-button-row > div .button {
    display: block;
    margin: 0;
    width: 100%;
}

.two-button-row > div {
    width: 49%
}
.go_second a {
    background: #383838
}

.two-button-row .delivery_submit:hover,
.go_second a:hover {
    background: #383838;
}

.error_postal_popup .popup_right .button {
    margin: 0
}

img.pop-scooter-img {
    max-width: 90px;
    margin-bottom: 15px
}

.receipt_popup,
.receipt_cateringpopup {
    max-width: 620px;
}

.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
    padding: 0;
}

.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
    padding: 0;
}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
    margin-top: 0
}

.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
    margin: 30px 0
}

.error_postal_popup .popup_ttsec {
    background: #ffa700;
}

.error_postal_popup h4 {
    color: #fff;
    font-size: 16px;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
    height: 309px;
}

.news_postal_popup .popup_ttsec {
    background: #b79a6c;
    width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
    background: url(../images/news_popupright.png) no-repeat;
    background-size: cover;
    width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
    font-size: 16px;
    margin: 6px 0 0 0;
}

.news_subscribe {
    position: relative;
    margin-top: 14px;
}

.news_subscribe .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
    background: #54362c;
    color: #fff;
}

.news_subscribe .form-control {
    height: 51px;
    border: 1px solid #fff;
}

.news_subscribe p {
    font-size: 16px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
    background: rgba(24, 24, 24, 0.5);
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 32px;
    line-height: 30px;
    opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.order-body {
    text-align: center;
    padding: 64px 0;
    border: 1px solid #b79a6c;
}

.order-body h2 {
    font-size:38px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.order-body p {
    color: #333;
    font-size: 16px;
    margin: 0 0 20px 0;
}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
    padding: 10px 30px 0 30px;
}

.order_delivery_item li {
    width: 48%;
}

.order_delivery_item li a {
    display: block;
    position: relative;
    background: #e5e5e5;
    padding: 15px 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}
.order_delivery_item li a.active, .order_delivery_item li a:hover{
    background: #8F0F33;
}
.header-ordernow-single-img{-webkit-transition: 0.3s linear all;transition: 0.3s linear all;}
.header-ordernow-single-img .order_type_imgwt{ display: none}
.order_delivery_item li a.active .order_type_imgwt, .order_delivery_item li a:hover .order_type_imgwt{ display: block; margin: 0 auto}
.order_delivery_item li a.active .order_type_img, .order_delivery_item li a:hover .order_type_img{ display: none}
.order_delivery_item h3 {
    color: #000;
    font-size: 18px;
    margin: 15px 0 0 0;
        font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
}
.order_delivery_item li a:hover h3, .order_delivery_item li a.active h3{ color: #fff}

.order_icon {
    display: block;
    text-align: center;
    margin: auto;
}


.order_delivery_item .tooltip {
    font-size: 16px;
}

.order_delivery_item .tooltip-inner {
    max-width: 260px;
}

.ordelivery_tooltip {
    position: relative;
}

.order_delivery_mobile_tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: -270px;
    top: 23px;
    background: #181818;
    padding: 10px;
    border-radius: 3px;
    width: 260px;
    margin: 0 auto 5px;
    z-index: 1;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
    opacity: 1;
    visibility: visible;
}

.order_delivery_mobile_tooltip:before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -7px;
    border: 10px solid #181818;
    border-color: transparent transparent transparent #181818;
    border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
    left: auto;
    right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
    border-color: transparent #181818 transparent transparent;
    left: -16px;
    right: auto;
}

.order_delivery_item li.disable .ordericon_link {
    display: none;
}

.order_delivery_item li.disable:hover {
    background: #f9f9f9;
    border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
    display: none;
}

.orderpop_address {
    position: relative;
    height: 100%;
    padding-top: 35px;
}

.orderpop_address p {
    font-size: 12px;
    margin: 0;
}

.orderpop_continue_shop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.orderpop_continue_shop .btn {
    font-size: 11px;
    height: 30px;
    padding: 7px 15px;
    margin: 0;
}

.orderpop_change_address {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
}

.orderpop_change_address:hover {
    color: #000;
}

.order_delivery_item li .header-ordernow-single-img img {
    height: 70px
}

.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close {
    background: #8F0F33;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;
        border: 1px solid #333;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after, .venue_popup .close-icon-but:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover{
    background: #282828;
}

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(1, 1, 1, 0.9);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(0, 0, 0, 0.9);
}

.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
    width: 100%;
    background: #000000;
    padding: 30px 20px;
}

.awesome_popup .btn_sec {
    max-width: 500px;
    margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    color: #000
}

.awesome_popup .popup_ttsec .button:hover {
    background: #ffa700;
    color: #fff
}

.awesome_del {
    margin-top: 13px;
    text-align: center;
}

.awesome_del h5 {
    font-size: 19px;
    margin-bottom: 10px;
    color: #fff;
}

.awesome_del h2 {
    color: #fff;
    font-size: 13px;
    font-family: 'avantgarde_bk_btbook';
    line-height: 19px;
}

.awesome_right h4 {
    font-size: 16px;
}

.awesome_popup {
    max-width: 640px;
}

.datetime_selt_lbl{ font-weight: 700; padding-bottom: 10px; color: #383838; font-size: 16px}
.delevery_popup_datetime .btn_sec .button{ display: block; margin: 0; width: 100%; }

.white-popup.self_popup {
    max-width: 560px;
}

.self-back {
    position: absolute;
    top: 13px;
    font-size: 18px;
    color: #fff;
    z-index: 1;
    left: 40px;
    text-transform: uppercase;
}

.self-back:hover {
    color: #000;
}

.self_popup .order-body {
    padding: 0 0 0px 0;
}

.self_popup_hea_row {
    background: #8F0F33;
    padding: 20px 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.self_popup_hea_col {
    text-align: left;
}

.order-body .self_popup_hea_row h2 {
    color: #fff;
}

.order-body .self_popup_hea_row p {
    margin-bottom: 0;
    font-size: 20px;
    color: #fff;
}

.self_popup_hea_col_left {
    padding-right: 15px;
}
.self_popup_hea_col_left img{width: 50px }

.self_pop_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 21px 15px;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_col {
    width: 50%;
}

.self_pop_item_loc {
    position: relative;
}

.self_pop_item_map {
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    border: 1px solid #bba075;
    height: 100%;
}

.selfpop_mapouter {
    height: 100% !important;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.self_pop_item_loc p {
    color: #595656;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 170px;
    margin: 0 auto;
    min-width: 288px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 23px;
    border-radius: 10px;
    height: 62px;
}

.loc_icon {
    width: 33px;
    height: 33px;
    background: url(../images/location_btn.png) no-repeat;
    margin-right: 10px;
}

.self_pop_col_right h4 {
    font-size: 16px;
    font-weight: 700;
    color: #595656;
    text-align: left;
    margin-left: 30px;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000000;
    font-size: 17px;
    text-align: left;
    margin: 0px 0 10px 5px;
   font-family: 'avantgarde_bk_btbook';
    font-weight: 700
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 190px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}
.self_outlet_inner li a {
    background: #f8f8f8;
    padding: 10px 15px;
    display: block;
    font-size: 15px;
    color: #595656;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}
.self_outlet_inner li a:hover,
.self_outlet_inner li.active a {
        background: #1e1e20;
        color: #fff;
}

.self_outlet .btn.cnt_btn {
    width: 100%;
    background: #72624f;
    margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
    background: #333;
}

.self_pop_locbx .form-group .form-control {
    width: 100%
}

.self_outlet .button {
    margin: 0;
    width: 100%;
    display: block;
    font-family: 'avantgarde_bk_btdemi';
}

.self_pop_locbx {
    margin: 0 0 20px 0
}

.white-popup.login-popup,
.white-popup.signup-popup,
.white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup,
.white-popup.guest-popup {
    background: inherit;
    max-width: 1086px;
}

.pop-whole-lhs-inner {
    text-align: center;
    max-width: 318px;
}

.pop-whole {
    position: relative
}

.login-popup .mfp-close,
.signup-popup .mfp-close, .forgot-password-popup .mfp-close,
.signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close,
.guest-popup .mfp-close {
    width: 30px;
    height: 30px;
    line-height: 30px;
    right: 49px;
    top: 0;
    font-size: 0;
    color: #afafaf;
    margin: 0;
    opacity: 1;
}
.login-popup .mfp-close, .signup-popup .mfp-close, .guest-popup .mfp-close, .forgot-password-popup .mfp-close{
    background: #000;
    right:5px;
    top: 5px;
}

.login-popup .mfp-close:hover,
.signup-popup .mfp-close:hover, .forgot-password-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover,
.signup-corparatetwo-popup .mfp-close:hover,
.guest-popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.login-popup .mfp-close:after,
.signup-popup .mfp-close:after, .forgot-password-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after,
.signup-corparatetwo-popup .mfp-close:after,
.guest-popup .mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}


.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer span {
    color: #000;
    font-size: 16px;
    margin: 0 0 3px;
}

.popup-footer .guest_btn {
    background: #70afa7;
    color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
    background: #333333;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}



.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}



.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
}

.redeem_popsec .pouup_in {
    padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
    font-size: 24px;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
        transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.location-part-outer {
    padding: 0px !important;
    position: relative;
    overflow: hidden;
}

.location-part iframe {
    width: 100%;
    height: 680px;
}

.location-sidemenu {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 320px;
    height: 100%;
    z-index: 8;
    width: 100%;
}

.location-sidemenu .red-bar {
    background: #db4337;
    padding: 7px 40px;
}

.location-sidemenu .three-lines {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    width: 18px;
    height: 12px;
    background: url(../images/spirite_whole.png) no-repeat scroll -122px -191px transparent;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 18px;
}

.location-sidemenu .three-lines:hover {
    background-position: -156px -191px;
}

.location-sidemenu .serch-box form {
    position: relative;
}

.location-sidemenu .serch-box input[type="text"] {
    margin: 0;
    height: 35px;
    border: none;
    padding: 7px 35px 7px 15px;
    background: transparent;
    color: #fff;
    outline: 0;
    font-weight: 700;
}

.location-sidemenu .serch-box input[type="text"]::-webkit-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]::-moz-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-ms-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-moz-placeholder {
    color: #fff;
}

.loc-cont-part {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
}

.location-sidemenu .serch-box input[type="submit"] {
    position: absolute;
    font-size: 0;
    right: 0;
    top: 0;
    margin: 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    min-width: inherit;
    border: none;
    border-radius: 0;
    background: url(../images/search-icon.png) no-repeat scroll center center transparent;
}

.location-sidemenu .three-dots {
    width: 19px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat scroll -193px -191px transparent;
    display: inline-block;
    position: absolute;
    right: 13px;
    top: 16px;
    cursor: pointer;
}

.outlet-list {
    max-height: 551px;
    overflow-y: auto;
}

.outlet-list > ul {
    padding: 20px 15px;
}

.outlet-list > ul > li {
    margin-bottom: 20px;
}

.outlet-list li {
    list-style: none;
    padding-left: 38px;
}

.outlet-list li span {
    display: block;
    font-weight: 700;
}

.outlet-list li .submenu {
    padding: 0;
}

.outlet-list li .submenu li {
    margin-top: 10px;
    list-style: none;
    padding: 0;
    line-height: normal;
    padding-left: 3px;
}

.outlet-list li .submenu li a {
    font-weight: 700;
    display: block;
    color: #272626;
    position: relative;
    padding-left: 14px;
    font-size: 11px;
}

.outlet-list li .submenu li a:hover {
    color: #ecdfcc
}

.outlet-list .custom_checkbox {
    position: absolute;
    left: 0;
    font-size: 0;
}

.outlet-list .custom_checkbox span:before {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -216px -189px;
}

.mapinfo_trigger {
    background: red;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
}

.mapinfo_canvas {
    background: #fff;
    width: 100%;
    max-width: 320px;
    position: absolute;
    left: -320px;
    top: -49px;
    bottom: 0;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 9;
}

.mapinfo_canvas.open {
    left: 0;
}

.mapcanvas_ttsec {
    background: #db4337;
    padding: 15.5px 20px 15.5px 49px;
    position: relative;
}

.mapcanvas_close {
    background: url(../images/left-arrow.png) no-repeat center;
    position: absolute;
    width: 49px;
    height: 49px;
    line-height: 49px;
    font-size: 24px;
    text-align: center;
    left: 0;
    top: 0;
}

.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}

.mapcanvas_ttsec h3 {
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 700;
}

.mapcanvas_bx {
    padding: 12px 18px;
    color: #000;
}

.mapcanvas_bx h5 {
    color: #888;
    margin: 0;
    font-size: 13px;
}

.mainacc_menusec {
    margin: 0 0 40px;
}

.mainacc_menulist {
    padding: 0;
    list-style: none;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom:4px solid #e3e3e3;
}

.mainacc_menulist > li {
    width: 50%;
    position: relative;
}
.mainacc_menulist > li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #8f0f33;
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
}
.mainacc_menulist > li >a {
    background: #fff;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 15px 10px;
    display: block;
    position: relative;
    font-family: 'avantgarde_bk_btdemi';
    letter-spacing: 0.1px;
    text-align: center
}

.mainacc_menulist > li.active:before,
.mainacc_menulist > li:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.mbacc_mslidersec {
    display: none;
}

.mainacc_moblink {
    display: none;
}

.myacc_hea {
    position: relative;
    margin: 0 0 14px;
}

.myacc_hea::after,
.myacc_pg::after {
    display: block;
    content: "";
    clear: both;
}

.myacc_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.myacc_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.myacc_hea_inner {
    margin-top: 8px;
}
.re_select .custom-react-selector-box__menu {
    z-index: 20;
  }
.box_in {
    background: #FCF5D5;
}

.account_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.accmenu_sec {
    background: #e8eaec;
    width: 265px;
    display: inline-block;
    position: relative;
    color: #fff;
}

.accprofile {
    padding: 26px 10px 0px;
}

.accprofile_img {
    width: 140px;
    height: 140px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    line-height: 134px;
    border-radius: 50%;
    margin: 0 auto 0;
    overflow: hidden;
}

.accprofile_img img {
    vertical-align: middle;
}

.accprofile_info p {
    font-size: 15px;
    color: #000;
    font-weight: normal;
    margin: 0;
}

.accpoints_sec {
    padding: 15px 7px 0px
}

.accpoint_tt span {
    background: #fff;
    padding: 0 4px;
    position: relative;
    display: inline-block;
}

.accearn_points {
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 15px 10px;
}

.accearn_pointtop {
    position: relative;
    padding-left: 52px;
}

.accearn_pointtop i {
    position: absolute;
    left: 0;
    top: 7px;
}

.accearn_pointtop h4 {
    font-size: 15px;
    margin: 0;
    color: #fff
}

.accearn_pointtop span {
    font-size: 45px;
    display: inline-block;
    line-height: 0.8;
    color: #ffc800;
}

.accearn_pointtop sub {
    font-size: 60%;
    bottom: 0;
}

.accearn_points p {
    font-size: 13px;
    margin: 0;
}

.accpoint_ec {
    position: absolute;
    right: 13px;
    bottom: 8px;
}

.accsec_right {
    width: calc(100% - 265px);
    width: -webkit-calc(100% - 265px);
    display: inline-block;
    padding: 40px 50px;
}

.accsec_right h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.accsec_right p {
    font-size: 17px;
}

.acc-inform .form-group {
    margin-bottom: 11px;
}

.other-add-body {
    height: 175px;
    border: 1px solid #f1efef;
    border-radius: 7px;
    padding: 16px 14px 0px 14px;
    background: #fff;
}

.other-add-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

.other-add-row li {
    width: 32.1875%;
    margin-right: 1.6666666666666666%;
    position: relative;
    margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}

.other-add-row span {
    position: absolute;
    top: 6px;
    right: 8px;
    background: url(../images/spirite_whole.png) no-repeat;
    width: 10px;
    height: 10px;
    background-position: -35px -315px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.other-add-row span:hover {
    opacity: 0.5;
}

.setting_menu_list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.setting_menu_list > ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.setting_menu_list > ul li {
    position: relative;
}

.setting_menu_list > ul > li > a {
    padding: 12px 10px 12px 20px;
    background: #e8eaec;
    color: #151515;
    display: block;
    text-transform: uppercase;
    border-bottom: 2px solid #fff;
    margin-bottom: 0;
    font-family: 'avantgarde_bk_btdemi';
}

.setting_menu_list > ul li a:hover {
    background: #6e1d24;
    color: #fff;
}

.setting_menu_list > ul li:last-child a {
    border-bottom: 0;
}

.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}
.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 30px 35px;
    z-index: 1;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}

.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -7px;
}

.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}

.myacc_dropmenu li a {
    color: #fff;
}

.myacc_dropmenu .tg-list {
    position: absolute;
    top: 0;
    right: -12px;
    padding-top: 0;
}

.main_tabsec_inner {
    padding: 20px 0px 0;
}

.main_tabsec_inner> .nav-tabs>li {
    width: 33.17%;
    margin-right: .24154589371980675%;
    position: relative;
}

.main_tabsec_inner> .nav-tabs>li:last-child {
    margin-right: 0;
}

.main_tabsec_inner> .nav-tabs {
    text-align: center;
}

.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner> .nav-tabs>li.active>a {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec_inner> .nav-tabs>li> a > span > b {
    font-weight: normal;
}

.count_num_t {
    background: #a7a7a7;
    width: 70px;
    height: 100%;
    text-align: center;
    font-style: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: #f1f0f0;
    font-size: 20px;
    border-top-left-radius: 3.2em 5em;
    border-bottom-left-radius: 3.2em 5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
    border-bottom: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    border-bottom: 5px solid #f0f0f0;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    background: #fbaa1a;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight:bold;
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight:bold;
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    font-weight:bold;
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
    color: #fff;
}

.cur-order-del li span {
    padding-left: 5px;
}

.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.calender-icon {
    background-position: -215px -258px;
}

.time-icon {
    background-position: -249px -257px;
}

.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}

.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cur-ord-rec li a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #2e2e2c;
}

.cur-ord-rec li a:hover {
    color: #909090;
}

.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}

.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}


.order-cat-hea {
    background: #fff;
    padding: 8px 35px;
    max-width: 331px;
    margin-bottom: 10px;
}

.order-cat h2 {
    font-size: 20px;
    color: #0b0405;
    margin-bottom: 0;
}

.order-cat-table {
    padding: 0 35px;
}

.order-cat-table h4 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 11px;
}

.order-cat-table table {
    border: 0;
    max-width: 364px;
    margin-bottom: 10px;
}

.order-cat-table table td {
    font-weight: 300;
    font-size: 16px;
    color: #3d3d3d;
    padding: 0 5px;
}

.order-cat-table table td.p_name {
    padding-left: 0;
}

.p_name {
    width: 247px;
}

.p_val {
    width: 80px;
}

.ord-amount {
    padding: 10px 0 0 35px;
    border-top: 1px solid #efefee;
}

.ord-amount .row {
    margin-right: -20px;
}

.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}

.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}

.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}

.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}

.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}

.congrats::after {
    display: block;
    content: "";
    clear: both;
}

.congrats-inner {
    margin: 10px auto 0;
    text-align: center;
    padding: 50px 15px;
    background: #6e1d24
}

.congrats-inner h3 {
    font-size: 50px;
    color: #fff;
    margin-bottom: 4px;
     font-family: 'the_secretregular';
    text-transform: capitalize;
}

.congrats-inner h2 {
    font-size: 42px;
    color: #ffdf00;
    margin-bottom: 0;
}

.rewards-tab-section .ord-amount {
    border-top: inherit;
    padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
    margin-bottom: 7px;
}

.earned_points {
    max-width: 300px;
    padding-left: 0;
    list-style: none;
    float: right;
    padding-right: 24px;
}

.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}

.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.redeem_popsec {
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 0px auto;
}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {
    width: 25%;
    height: 270px;
}

.redeem_popsec .promo-earned-content {
    padding: 30px 20px 30px;
    text-align: center;
}

.redeem_popsec .promo-earned-content h4 {
    font-size: 24px;
    margin: 0px 0px 10px 0px;
}

.redeem_popsec .promo-earned-content h2 {
    font-size: 42px;
    color: #3c3c3c;
}

.redeem_popsec .promo-earned-content p {
    margin: 7px 0 0;
}

.redeem_popsec .abc_code {
    padding: 20px 0 0px;
}

.redeem_popsec .promo-earned-content h2 span sup {
    font-size: 26px;
}

.redeem_pop_image img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.redeem_popsec .pouup_in {
    padding: 30px 55px 30px;
}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}

.redeem_popsec .btn {
    background: #fbaa1a;
    color: #fff;
    display: block;
    border-radius: 6px;
    margin: 0 auto 0px;
    text-align: center;
}

.redeem_popsec .btn:hover {
    background: #200200;
    color: #fff;
}

.redeem_popsec .mfp-close {
    background: #d9d7d7;
    border-radius:5px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    top: 10px !important;
    right: 10px !important;
}

.redeem_popsec .mfp-close:after {
    background: url(../images/cross-close.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.redeem_popsec .mfp-close:hover {
    background: #b5b5b5;
}

.input_area {
    position: relative;
}

.input_area {
    position: relative;
}

.cal_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 45px;
    pointer-events: none;
}

.cal_icon b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro {
    display: block;
    margin: 0 auto;
    background: #3d5a98;
    text-transform: inherit;
    border-radius: 5px;
    width: 100%;
    padding: 8px 15px;
    font-size: 15px;
        font-family: 'avantgarde_bk_btdemi';
    font-weight: normal;
    border: 1px solid #cbcbcb;
    box-shadow:0px 7px 9px 0px rgba(0, 0, 0, 0.21);
        display: flex;
    justify-content: center;
    align-items: center;
}
.pop-whole .kep-login-facebook.metro i{ font-size: 25px; }

.pop-whole .kep-login-facebook.metro:hover {
    background: #333;
    color: #fff;
}

.combo-input-label {
    height: 100%;
    border: none;
    color: #000;
    font-size: 13px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    border-radius: 0px;
    border-right: 2px solid #e0e0e0;
    border-left: 2px solid #e0e0e0;
    line-height: 21px;
}

.compo_acc_innerdiv {
    padding: 10px 20px 0px 20px;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #523429;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
    margin: 0px 0 30px;
}

.cms-page .container {
    padding: 30px 15px 40px;
   /* background: #fff;*/
    background: #FCF5D5;
    border-radius: 5px;
}

.cms-page h3 {
    font-size: 27px;
    color: #040507;
}

.abt-cms-lhs {
    width: 53%;
    font-size: 16px;
    color: #fff;
}

.abt-cms-rhs {
    width: 42%;
}
.abt-cms-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.abt-cms-lhs h2 {
    color: #ffdf00;
    font-family: 'the_secretregular';
    text-transform: inherit;
    font-size: 47px;
    margin-bottom: 10px;
}

.contact_left p a {
    color: inherit;
}

.contact_left p a:hover {
    color: #b69a6b;
}

.contact_left p.open-hours {
    border-top: 1px solid #eaeaea;
    padding-top: 25px;
    margin-top: 26px;
}

.contactus_pg .contact_form h3 {
    margin: 0 0 5px;
}

.contact_form .help-block {
    margin: 0 0 24px;
}

.product_tag {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 5px 0 10px;
}

.product_tag li {
    padding-right: 10px;
}

.product_tag li b {
    margin-right: 10px;
}

.product_tag li a {
    display: inline-block;
    color: #5b5b5b;
    font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    margin: auto;
    text-align: center;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    max-width: 1260px;
    padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
    top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
    right: 0;
}

.chosen_file {
    position: relative;
    display: inline-block;
}

.chosen_file input[type="file"] {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosen_file label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
    margin: 0;
    pointer-events: none;
}

.catering .ttsplitter span {
    background: #fff;
}

.catering .ttsplitter {
    margin: 0 auto 20px;
}

.container_720 {
    margin: 0 auto;
    max-width: 720px;
}

.date_list {
    margin-bottom: 30px;
    position: relative;
    padding: 40px 120px 30px;
    border: 2px solid #dddddd;
    background: #fff;
    border-radius: 6px;
}

.date_list:after {
    content: "";
    display: table;
    clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
    text-align: center;
    font-size: 26px;
    margin: 0 0 20px 0;
    font-family: 'avantgarde_bk_btbook';
}

.ordering-more {
    text-align: center
}

.ordering-more .button.no {
    background: #000;
}

.ordering-more .button.yes {
    background: #ffa700;
}
.more-time-slots{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 476px;
    margin: 0 auto;
}
.more-time-slots a{
    width: 49%;
    margin: 0;
    padding: 17px 5px 16px;
    border-radius: 5px;
        font-size: 19px;
}

#nobtn_id {
    margin-right: 5px
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
    background: #333;
}

.ordering-more h3 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
    font-family: 'avantgarde_bk_btbook';
    
}

.date_list h4 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
     font-family: 'avantgarde_bk_btbook';
}

.venue-details-inner h3 {
    display: block;
    font-size: 14px;
    color: #3c3a3a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 5px;
}

.show {
    display: block;
}

.no-padding {
    padding: 0px;
}
.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}

.making-catering {
    width: 36%;
    float: left;
    padding: 5px 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.17)
}

.making-catering p {
    margin: 0;
}

.making-place {
    font-weight:bold;
    font-size: 16px;
}

.making-place.making-place-padding {
    display: block;
}

.change-location a {
    text-decoration: underline;
    font-size: 17px;
    color: #000;
}

.change-location a:hover {
    color: #ffa700;
}

.event-time {
    width: 33%;
    padding: 5px 0px 5px 20px;
}

.event-time .title4 {
    margin-bottom: 3px;
}

.event-time .row {
    margin: 0 -4px;
}

.event-time [class*="col-"] {
    padding: 0 4px;
}

.event-time .form-group {
    margin: 0;
}

.event-time .form-control {
    height: 57px;
}

.order-select-brktime ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.order-select-brktime ul .time-cls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
    margin: 0;
    position: relative;
    border: 1px solid #e4e4e4;
}

.order-select-brktime ul .time-cls label {
    width: calc( 100% - 200px);
    font-size: 18px;
    font-weight:bold;
    color: #020202;
    text-transform: uppercase
}

.viewmenu-top-div {
    margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
    padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
    font-size: 30px;
    top: 5px;
}
ul.outletul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.outletul > li {
    width: 49%;
    margin: 0 2% 30px 0;
    background: #fbaa19;
    position: relative
}

.outletul > li:nth-child(even) {
    margin-right: 0
}

.ourrest_img {
    width: 100%;
}

.ourrest_img img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.ourrest_info {
    width: 100%;
    padding: 30px 20px 70px 20px;
    color: #fff
}

.ourrest_info h4 {
    margin: 0 0 15px;
    color: #fff;
    font-size: 22px
}

.ourrest_info p {
    margin: 0 0 8px;
}

.ourrest_infolinks {
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ourrest_infolinks li {
    display: block;
    width: 50%;
}
.phone-show-mbl {
    display: none;
}

.phone-show-mbl a {
    color: #fff
}
.ourrest_infolinks a {
    padding: 15px 5px;
    display: block;
    color: #fff;
    transition: all 400ms;
    height: 100%;
    background: #6e1d24;
    text-transform: uppercase;
    font-family: 'avantgarde_bk_btdemi';
    letter-spacing: 1px;
}
.ourrest_infolinks a i{display: none}
ul li .cat-product-info{
    background: rgba(0, 0, 0, 0.60);
    padding: 45px 15px 45px;
    color: #fff;
    line-height: 1.4;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.35s ease-in-out;
        display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
ul li .cat-product-info h4{color: #ffffff;margin: 0 0 5px 0; }
.cat-product-button{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.cat-product-button a{
    margin: 0;
    display: block;
    height: 45px;
    padding: 15px 10px;
}
.btn_download {
    font-size: 18px;
    color: #fff;
    padding: 8px 20px 7px;
    height: 38px;
    border: 0;
    border-radius: 30px;
    background: #ffa700;
    -webkit-box-shadow: 0 2px 0px 0px #0b0405;
    box-shadow: 0 2px 0px 0px #0b0405;
    margin: 6px 0px 12px;
}

.btn_download:hover {
    background: #ecdfcc;
    color: #0b0405;
    -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
    box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.cross_multi {
    font-size: 15px;
    color: #5d5d5d;
    margin: 0 0px 5px 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 6px 12px;
    z-index: 1;
    background: #ffffff;
    font-family: 'avantgarde_bk_btdemi';
    letter-spacing: 1px;
    border: 1px solid #d6d6d6;
    line-height: 1;
    border-radius: 4px;
    text-align: center;
}

.checkout_time {
    padding: 0px 0px 20px;
}

.checkout_time label {
    color: #000000;
    line-height: 40px;
    padding: 4px 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight:bold;
}

.total_amt {
    background: #1c1c1c;
    padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
    font-size: 16px;
    color: #fff;
    font-weight:bold;
}

.total_amt .total {
    font-size: 25px;
    color: #fff;
    font-weight:bold;
    text-transform: uppercase;
}

.total_amt .rt {
    text-align: right;
}

.brline {
    background: #f5f5f5;
    height: 2px;
    margin-left: 40px;
}

.download-quote {
    padding: 20px 0px 0px;
    text-align: center;
}

.download-quote .btn {
    font-size: 18px;
    color: #fff;
    padding: 7px 20px;
    border: 0;
    background: #ffa700;
}

.catering_thak_you .tnk-header {
    text-align: unset;
}

.catering_thak_you .tnk-detail {
    text-align: center;
}

.catering_thak_you .tnk-header .tick {
    text-align: center;
}

.catering_thak_you .order_details .qtybxs {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    line-height: 38px;
    text-align: right;
}

.catering_thak_you .package .package-list {
    padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
    border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
    margin-left: 0px;
}

.promotion_popup {
    font-size: 16px;
    max-width: 650px;
}

.promo_poptt {
    margin: 0 0 30px;
}

.promo_poptt .title1 {
    margin-bottom: 8px;
}

.popoff_bar {
    padding: 10px 0 15px;
    border: 1px solid #ddd;
    border-right: 0;
    border-left: 0;
    margin: 0 0 35px;
}

.popoff_bar span {
    display: block;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}

.popoff_bar b {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
}


.popwel_formsec h4 {
    margin: 0 0 10px;
}

.popwel_inputbx {
    position: relative
}

.popwel_inputbx .form-control {
    height: 51px;
    font-size: 16px;
}

.popwel_inputbx .btn {
    position: absolute;
    right: 0;
    top: 0;
    border-color: #ecdfcc;
}

.rating-option {
    position: relative;
    display: inline-block;
}

.rating-option input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.rating-option span {
    padding: 5px 0 0 40px;
}

.rating-option span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid #aba8a3;
    left: 0;
    top: 3px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.rating-option input[type=radio]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #72624f;
    border-color: #ecdfcc;
}

.contact_left p {
    margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

#pay-conf-popup .process_inner {
    text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
    display: inherit;
    width: auto;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 98;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.htico_search > a, .htico_search_mbl > a {
    position: relative;
}

.header_bot {
    display: inline-block;
}

.home-banner-full {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-top p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0px;
}
.header-top-cls {
    padding: 17px 0;
    position: relative;
}

.logo img {
    width: 100px;
    transition: 0.3s linear all;
}
header.small_sticky .logo img{ width: 100px; }
.logo-main-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    align-items: center;
}
.menu_icon.trigger_menunav_act {
    display: none;
}
.mbl_search_liblk{display: none}

.slick-slide {
    position: relative;
}

.wppc-checked {
    color: red;
}

.wppc-rating-full {
    background-image: url("../images/star-bg.png");
    background-repeat: no-repeat;
    padding: 5px 10px;
    margin-top: 0px;
    margin-left: 44px;
}
.home-options{ padding: 100px 0 50px 0; position: relative;z-index: 1}
.home-options-inner > ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.home-options-inner > ul > li {
    max-width: 402px; 
    width: 31.4%;
    margin: 0 1.4% 20px 0;
    /*background: #fff;*/
    background: #FCF5D5;
    position: relative;
    box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.10);
    z-index: 1;
}
.home-options-inner > ul > li:hover {
    z-index: 9;
    /*background: #FCF5D5;*/
    background: #fff;
    outline: 2px solid #FFCD32;
}
.home-options-inner > ul > li:nth-child(3n){margin-right: 0;}
.home-option-img{position: relative;z-index: -1}
.home-option-img img{ width: 100%;max-height: 219px;}
.home-option-img h4{ font-family: 'the_secretregular'; text-transform: inherit;color: #fff; font-size:35px;line-height: 1; margin: 0}
.home-option-desc{ padding:25px 20px }
.home-option-desc p{ margin: 0;}
.home-option-parent {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
}
.home-option-desc h5 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(15px + (27 - 15) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 600;
    color: #141412;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.home-option-desc .home_desc {
    font-family: 'Open Sans', sans-serif;
    font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300)));
    color: #030302;
}
.home-order-btn {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.home-order-btn .home-btn {
    font-family: 'Poppins', sans-serif;
    /* font-size: calc(15px + (19 - 15) * ((100vw - 300px) / (1600 - 300))); */
    font-size: calc(10px + (19 - 10) * ((100vw - 300px) / (1600 - 300)));
    padding: 15px 30px;
}

.home-order-btn .home-btn.home-nohover:hover{
    background: #8F0F33;
}

/* .home-options-inner > ul > li:before {
  transition: all 0.4s;
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  left: 50%;
  margin-left: -50%;
  top: 25%;
  border-color: rgba(200, 200, 200, 0);
  border-style: solid;
  border-width: 0 3px;
        
}
.home-options-inner > ul > li:after {
  transition: all 0.4s;
  position: absolute;
  content: "";
  width: 50%;
  height: 100%;
  left: 50%;
  margin-left: -25%;
  top: 0;
  border-color: rgba(200, 200, 200, 0);
  border-style: solid;
  border-width: 3px 0;
    
} */

.home-options-inner > ul > li:hover:after {
  width: 100%;
  margin-left: -50%;
  border-color: #000;
}
.home-options-inner > ul > li:hover:before {
  height: 100%;
  top: 0%;
  border-color: #000;
}
.home-options-inner h2{ padding-bottom: 10px }

.subtle {
    position: absolute;
    left: 0;
    right: 0;
    background: rgba(41,41,41, 0.50);
    padding: 15px 5px 10px;
    top: 50%;
    transform: translateY(-50%);
}
footer {
    position: relative;
    margin: 50px 0 0;
}

.footer-bottom {
    padding-top: 30px;
    padding-bottom: 15px;
}

.footer-bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


.footer-contact a,
.footer-bottom-right h3 {
    font-size: 15px;
    text-decoration: none;
    color: #000;
}

.footer-bottom-middle ul,
.footer-bottom-right ul {
    list-style: none;
    padding: 0;
}

.footer-bottom-middle ul li a {
    color: #000;
    text-decoration: none;
    font-size: 17px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    text-transform: uppercase;
    font-family: 'avantgarde_bk_btbook';
    position: relative;
    padding: 1px 0;
}
.footer-bottom-middle ul li a::after, .footer-bottom-middle ul li a::before {
    position: absolute;
    content: "";
    opacity: 1;
    width: 0;
    height: 1px;
    background-color: #ffa700;
    -webkit-transition: width 0.4s 0.1s;
    transition: width 0.4s 0.1s;
}
.footer-bottom-middle ul li a::before {
    top: 0;
    right: 0;
}
.footer-bottom-middle ul li a::after {
    top: 94%;
    left: 0;
}
.footer-bottom-middle ul li a:hover::after, .footer-bottom-middle ul li a:hover::before {
    width: 100%;
}
.footer-bottom-middle ul li a:hover{ color: #ffa700; }

.footer-bottom-middle ul li {
    display: inline-block;
    margin: 0 7px
}

.footer-bottom-right ul li a {
    color: #000;
    font-size: 22px;
}
.footer-bottom-right ul li a:hover {
    color: #ffa700;}

.copyright-section p {
    font-family: 'CenturyGothic';
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
}

.home-banner {
    position: relative;
    z-index: 2;
    text-align: center;
}
.home-banner .loader-main-cls > img { filter:grayscale(0.6) ; -webkit-filter:grayscale(0.6) ; }
.home-banner .slick-slider .slick-prev {
    
    left: 0px;
}
.home-banner .slick-slider .slick-prev:before, .home-banner .slick-slider .slick-next:after{ background: url(../images/banner-arrow.png) no-repeat; position: absolute; left: 0; right: 0; top: 0;bottom: 0; width: 9px; height: 17px; content: "";margin: auto;}
.home-banner .slick-slider .slick-next:after{ transform: rotate(180deg); }
.home-banner .slick-slider .slick-next {
    right: 0px;
    
}

.home-banner .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    outline: 0;
    width: 33px;
    height: 46px;
    text-indent: -9999px;
    z-index: 9;
    border: none;
    padding: 0;
    margin: 0;
    background: #060801;
    border-radius: 0;
}
.home-banner .slick-arrow:hover {background: #8F0F33;}
.home-banner .btn-act-clss {
    background: #6e1d24;
    border: 1px solid #6e1d24;
}
.home-banner .btn-act-clss:hover {
    background: #ffa700;
    border: 1px solid #ffa700;
}

.container-full{ 
    max-width: 1530px;
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
}
.footer-nav-info {
    padding: 10px 0 20px 0;
}
.home-options-inner h3 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(17px + (59 - 17) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 600;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
}
.imp-button{display: flex;}
.imp-button a{width: 50%; padding:5px 5px; position: relative; text-align: center;font-family: 'avantgarde_bk_btdemi'; display: flex;
    justify-content: center; align-items: center;}
.imp-button a img{margin-right: 5px;}
.imp-button a:first-child{ color: #fff; background: #fbaa1a;     border-top-left-radius: 5px; }
.imp-button a:last-child{ color: #979797; background: #292929;     border-top-right-radius: 5px;  }

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}


.slick-track .slick-slide .featured-products-single {
    height: 100%;
    background-color: #fff;
}

.addcart_row .qty_bx {
    float: left;
}

.addcart_row .btn {
    margin: 0 0 0 2%;
    float: left;
    font-size: 14px;
    color: #fff;
    height: 32px;
    border: none;
    cursor: pointer;
    padding: 5px 15px
}

.addcart_row .btn:hover,
.addcart_row .btn:focus {
    background: #000;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-item-text h4 {
    font-size: 20px;
    font-family: 'avantgarde_bk_btdemi';
    color: #0a0b10;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}

.bakery-review .fa-star {
    color: #000;
}

.bakery-review .wppc-checked {
    color: #ed8a19;
}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    margin-top: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    font-family: 'avantgarde_bk_btdemi';
    color: #000;
    margin-bottom: 0;
}

.innerproduct-item-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.menu-section_full {
    overflow: hidden;
}
.login-popup, .signup-popup, .guest-popup, .forgot-password-popup{
    max-width: 980px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    position: relative;
}
.signup-popup .custom_checkbox input {width: auto;}
.full-login-new{
    background: #f6f6f6;
}
.full-login-new-header{ background: #fff; padding: 30px 30px; text-align: center;box-shadow:0px 4px 18px 0px rgba(2, 2, 2, 0.04); }
.full-login-new-header h3 {
    font-size: 45px;
    margin-bottom: 0px;
    line-height: 1;
}

.full-login-new-header p {
    margin-bottom: 0;
    font-size: 17px;
}
.full-login-new-body {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    padding: 60px 0;
    text-align: center;
}
.full-login-new-body h4{display: none}
.login_pop_sub button{width: 100%;box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.21); }
.login_pop_sub button:hover{ background: #fbaa1a; }

.checkout-heading {
    background: #8f0f33;
    color: #fff;
    font-size: 17px;
    padding: 10px;
    position: relative;
    font-family: 'avantgarde_bk_btdemi';
}
.controls.two-links a{  font-family: 'avantgarde_bk_btdemi'; color: #1d1d1d; text-transform: uppercase;font-size: 15px; width: 50%;
    display: block;}
.controls.two-links { display: flex; justify-content: center; align-items: center }
.controls.two-links a:first-child{ padding-right: 12px; text-align:right}
.controls.two-links a:last-child{ padding-left: 12px; text-align: left; }
.controls.two-links a:hover{ color: #8F0F33; }
span.spilter {
    width: 2px;
    height: 24px;
    display: block;
    background: #d3d4d4;
}
.full-login-new-body .sign_reg{ display: inline-block; vertical-align: middle}
.full-login-new-body .sign_reg p{margin-bottom: 0}
.full-login-new-body .sign_reg a {color: #000;text-decoration: underline;}
.full-login-new-body .custom_checkbox span{ width: 16px; height: 16px;    vertical-align: middle;    margin-right: 5px;}
.full-login-new-body .custom_checkbox span:before{border-radius: 0; width: 16px; height: 16px;}
.full-login-new-body .custom_checkbox input[type=checkbox]:checked + span:before{ background: url(../images/tick-16.png) no-repeat; border: 0 }

.or-seperator {
    margin: 20px 0 15px;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
    color: #868686;
    font-size: 18px;
    position: relative;
    z-index: 1;
}

.or-seperator:before {
    height: 2px;
    background: #e1e1e1;
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}
.or-seperator span{
    background: #f6f6f6;
    padding: 0 10px;
}
.header-top-msg {background: #1B1B1A;text-align: center;}
.header-top-msg p {color: #fff;margin: 0;padding: 14px;font-family: 'CenturyGothic';font-size: 15px;}
header {
    position: relative;
    top: 0;
    z-index: 99;
    margin: auto;
    width: 100%;
    background: #060801;
}

.logo a {
    position: relative;

}

.header-bottom .header-bottom-innersec {
	position: relative;
}

ul.hmenu_list > li > a {
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    color: #fff;
    text-transform: capitalize;
    padding: 10px;
    display: block;
    transition: 0.3s linear all;
    position: relative;
    margin: 0 3px;
    line-height: 1;
}

ul.hmenu_list > li > a:after{
    bottom: 0;
    content: "";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    background: #8F0F33;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
ul.hmenu_list > li > a:hover:after, .hmenu_list > li.active > a:after{ 
    width: 100%;
    left: 0;
}

.hsign_sec > span {
    font-size: 19px;
    vertical-align: text-bottom;
    position: relative;
    top: 4px;
    color: #fff;
    margin: 0 5px;
}

.hsign_sec a {
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    color: #fff;
    text-transform: capitalize;
}

.hsign_sec a:hover, .hsign_sec a.active {
    color: #ffdf00;
}

.haction_sec {
    text-align: center;
    position: relative;
}

.hmenu_actions {
    position: absolute;
    top:10px;
    right: 0;
    padding: 0;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.htico_search> a > img{
    width: 23px;
    height: 23px;
    display: block;
}

.htico_cart> a img {
    width: 22px;
    height: 28px;
    display: block;
}

.header_in {
    padding-top: 45px;
    position: relative;
}



.hcart_round {
    background: #8F0F33;
    min-width: 21px;
    min-height: 21px;
    font-family: 'CenturyGothic';
    font-size: 10px;
    border-radius: 100%;
    position: absolute;
    right: -8px;
    top: -5px;
    text-align: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.home-banner .slick-track .slick-slide > div {
    line-height: 0;
    width: 100%;
}

.featured-products-full .slick-dots {
    display: none !important
}

.checkout-terms-and-condition {
    height: 136px;
    overflow-y: auto;
    margin: 0 0 15px 0;
    font-size: 16px;
    border: 1px solid #d0cfcb;
    padding: 15px 10px
}

a.change-edt {
    color: #666;
    border-bottom: 1px solid #999;
    margin: 5px 0 0 0;
    display: inline-block;
    font-size: 16px;
}

a.change-edt:hover {
    color: #ffa700;
    border-color: #ffa700;
}

.page404-main-div {
    text-align: center;
      height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-flow: column wrap;
   flex-flow: column wrap;
}

.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    background: #000;
    padding: 15px 50px;
    border-radius: 4px;
    text-align: center;
    background: #000;
    margin: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    color: #FFF;
}
.page404-main-div h1{     
    margin-bottom: 10px;
    margin-top: 40px;
    color: #fff;
}
.copyright-section {
    padding: 15px 10px
}

.innerproduct-button .disabled {
    background: #d5d1d2 !important;
    color: #ffa700;
}

.innerproducts-list-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.innerproduct-button .btn_minwid {
    background: #98684a;
}

.prodetailinner-main-div {
    min-height: 105px;
}

.product-detail-empty {
    padding: 52px 0px 35px 0px;
    text-align: center;
    color: #cb4d36;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.custom-select-bxcls {
    position: relative
}

.custom-select-bxcls:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    height: 48px;
    pointer-events: none;
        border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.custom-select-bxcls:after {
    height: 48px;
}

.custom-select-bxcls select {
    margin: 0 !important;
    width: 100%;
    height: 50px;
    padding-left: 10px;
}

.disable-addtocart {
    pointer-events: none;
    cursor: default;
}

.disable-addtocart:after {
    background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    content: '';
}

.modfr-alert-danger {
    display: none;
    background: #ffdfdf;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    color: #c10606;
}

.modfir_addcart_cls .qty_bx input {
    color: #000;
}

.mdf_common_rows {
    position: relative;
}

.compo_pro_acc {
    background: #F9F9F9;
}

.prd_chosen_row.compo_top_div {
    background: #ffffff;
}

.compoMdfr_item_left {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.compoMdfr_item_left .product_chosen_addons {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.compoMdfr_item_left .product_chosen_hea {
    margin: 0px;
}

.compoMdfr_item_left .form-group {
    width: 70%;
    float: left;
    margin: 0;
}

.compoMdfr_item_left .product_chosen_hea h6 {
    font-size: 14px;
}

.compopro_acc_head {
    padding: 12px 20px 12px 20px;
    cursor: pointer;
}

.compopro_acc_head::before,
.compopro_acc_head::after {
    background: #5e5e5e;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: -webkit-transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}

.compopro_acc_head::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.compopro_acc_head::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.compo_acc_active .compopro_acc_head::after {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

.text-uppercase {
    text-transform: uppercase
}

.product-rhs .cart_footer {
    padding: 20px 20px;
    margin-top: 15px;
}
.product-rhs .product_orders_top{display: none}
.cart_row.grant-total-cls p,
.cart_row.grant-total-cls span {
    font-size: 20px;
    line-height: 1.2;
    color: #353535;
    font-family: 'avantgarde_bk_btdemi';
}

.cart_row.grant-total-cls sup {
    font-size: 100%;
    position: relative;
    top: 0px;
}

.cart_footer .cart_row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.cart-header-first h4 {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
   font-family: 'Open Sans', sans-serif;
}

.cart-header-first p {
    color: #000000;
    margin: 0
}

.cart-header-second h5 {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-second p {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {
    top: 6px;
    right: 0px;
}

.product-details {
    padding: 10px 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    
}
.order-details-with-clear, .order_card_head{display: flex; justify-content: space-between; padding: 10px 15px;background: #8F0F33; align-items: center}
.order-details-with-clear h5, .order_card_head h5{ color: #fff; margin: 0; font-size: 15px; letter-spacing: 0.3px; }
.cart_body {
    padding: 20px 12px 20px;
}
.overall-parent{ border: 1px solid #8f0f33; border-radius: 5px; }

.cartaction_bottom .btn {
    margin: auto;
    width: 100%;
    text-align: center;
    display: block;
    background: #8f0f33;
    padding: 10px 10px;
    border-radius: 5px;
    height: auto;
    font-size: 16px;
        letter-spacing: 0.5px;
    color: #fff;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
    transition: 0.3s linear all;
}

.cartaction_bottom .btn:hover {
    background: #ffdf00;
    color: #000;
}
.cart_row.grant-total-cls {
    border-top: 3px solid #f4f4f5;
    padding-top: 10px;
    margin-bottom: 20px;
}
.Viewreceipt .cart_row.grant-total-cls{ border-top: 0; margin-bottom: 0; padding-top: 0 }
.Viewreceipt .tnk-delivery .cart_footer.tnk_cart_footer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}
.Viewreceipt .delivery_total::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}
.Viewreceipt .tnk-delivery .delivery_total_number:before{
    display: none;
}

.cart-header-first .row-replace,
.cart-header-second .row-replace,
#cart-close-span{    
display: block;
position: relative;
order: 2;
width: 24px;
height: 22px;
cursor: pointer;
}

#cart-close-span:before, #cart-close-span:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #fff;
}
#cart-close-span:before {
    transform: rotate(45deg);
}
#cart-close-span:after {
    transform: rotate(-45deg);
}

.cart-header-first .row-replace .col-sm-cls,
.cart-header-second .row-replace .col-sm-cls {
    width: 50%
}
.cart_left {
    width: calc( 100% - 95px );
}
.cart_right {
    width: 95px;
}
.gst-row {
    margin-top: 0px;
}
.productlist-main-div {
    padding: 35px 35px 30px;
}


.del_address h5{ margin-bottom: 10px;    font-size: 15px; letter-spacing: 0.3px;}
.del_address{ border-bottom: 1px solid #f4f4f4; margin-bottom: 20px; padding-bottom:5px; }
.del_address .form-control {padding: 10px 10px; height: 38px;font-family: 'Helvetica';color: #959595;font-size: 14px;}
.form-group.half-fg {
    display: flex;
    justify-content: space-between;
}
.form-group.half-fg .input_field{ width: 49%; }
.cart_body .hclear_cart:hover {
    color: #98684a
}

.compo_minmax_maindiv .combo-input-label {
    display: block;
}

.compo_minmax_maindiv .max-min-bar {
    padding-bottom: 10px;
    font-size: 16px
}

.compo_minmax_maindiv .error_combo_div {
    color: red;
    padding-bottom: 10px;
    display: none;
}

.compo_minmax_maindiv .combo_pro_price {
    color: #ffa700;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.custom_close span {
    color: red;
}

.alert-success:before,
.single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.btn-medium {
    min-width: 190px;
}

.cart_row.cart-header-second {
    margin: 15px 0 0 0;
}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}
.tnk-delivery .delivery-cart-div .cart-direction::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 1px solid #dfdad7;
}
.innersection_wrap {
    padding: 20px 0 0 0;
}

.tnk-chk-order .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 18px 20px 18px;
    font-size: 17px;
}

.thank-order-detaildiv {
    max-width: 580px;
    margin: 0 auto;
    -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
}

.mainacc_toptext {
    text-align: center;
    padding: 0 0 20px 0;
}

.mainacc_toptext h2 {
    margin: 20px 0 5px 0
}

.mainacc_toptext p {
    font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
    margin: 0;
    color: #fff;
}
.myacc_order_details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.myacc_order_details .current_order {
    width: 48.5%;
    margin-bottom: 20px;
    background: #f9f9f9;
    padding: 0 0 120px 0;
    position: relative;
    box-shadow: 1px 1px 8px #00000045;
}

.myacc_head_sec {
    background: #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    overflow: hidden
}

.head_right .head-group {
    z-index: 2;
    position: relative
}

.head_left h4 {
    color: #000000;
    margin: 0;
    font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300)));;
}

.head_right h4 {
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.head_right::before {
    content: "";
    display: block;
    background: #6e1d24;
    width: 35%;
    position: absolute;
    right: -25px;
    height: 100%;
    top: 0;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
}

.order_details_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.order_btns,
.order_amt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.order_btns .button {
    margin: 0;
    width: 50%;
    display: block;
    padding: calc(10px + (16 - 10) * ((100vw - 300px) / (1600 - 300))) calc(7px + (10 - 7) * ((100vw - 300px) / (1600 - 300))) ;
    color: #000;
    border-radius: 0;
    box-shadow: none;
}
.order_btns .button.view_recipt {
    background: #8f0f33;
    border: 2px solid #8f0f33;
    color: #fff;
}
.order_btns .button.view_recipt:hover {
    background: #ffdf00;
    color: #000;
    border: 2px solid #ffdf00;
}
.order_btns .print_invoice {
    background: #f0f0f0;
    border-right: 2px solid #fff;
}

.order_btns .view_recipt {
    background: #f0f0f0;
     border-left: 2px solid #fff;
}
.order_btns .view_recipt.button_full_view{width: 100%}
.order_btns .button:hover {
    color: #fff;
    background: #8f0f33;
}

.order_amt {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #f6f6f6;
    padding: 16px 15px
}

.order_amt h3 {
    margin: 0;
    color: #000;
    font-size: calc(15px + (22 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.order_amt-right sup {
    font-size: 75%;
    position: relative;
    top: -5px;
    margin: 0 2px 0 0;
}

.order_details_body {
    padding: 15px
}

.order_no_deatails {
    margin: 0 0 20px 0;
    text-align: center
}

.order_no_deatails span {
    display: block;
}

.delivery_total_left h3 {
    font-weight:bold;
}

.delivery_total_left img {
    margin: 0 0 10px 0
}

.delivery_total {
    position: relative;
}

.delivery_total_text::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}

.web_order_typecls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
    margin: 0 0 50px 0
}

.web_order_typecls > li {
    width: 50%;
    border-left: 2px solid rgba(255, 255, 255, 0);
}

.web_order_typecls li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 17px 10px;
    background: #e4e4e4;
    color: #000
}

.web_order_typecls li.active a,
.web_order_typecls li a:hover {
    background: #ffdf00;
    color: #000;
}

.web_order_typecls li a h5 {
    margin: 0;
    color: #000;
    text-transform: uppercase
}
.web_order_typecls li.active a h5, .web_order_typecls li a:hover h5{ color: #000; }

.sprite_lst_icon {
    margin: 0 10px 0 0
}

.sprite_lst_icon.order_t {
    max-width: 33px;
}

.sprite_lst_icon.catering_icon_t {
    width: 22px;
}

.sprite_lst_icon.reserve_icon_t {
    width: 22px;
}

.myacc_order_details .reserv-orderlist {
    padding: 0;
    border-bottom: 2px solid #000000
}

.myacc_order_details .reserv-orderlist .order_no_deatails {
    margin: 15px 0px 0;
    border-top: 1px solid #e4e4e4;
    padding: 20px 0 0 0;
}

.myacc_order_details .reserv-orderlist .order_no_deatails h3 {
    margin: 0;
    color: #000;
    font-size: 19px;
    text-transform: uppercase;
}

.myacc_order_details .reserv-orderlist .order_no_deatails p {
    margin: 0;
    color: #98684a;
    font-size: 21px;
    font-weight:bold;
}

.reserv-loader-div {
    display: inline-block
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 19px;
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h2 {
    margin-bottom: 5px
}

.reserv-orderlist .delivery_total.delivery_total_number {
    margin: 0;
    padding: 0
}

.web_order_typecls li:hover .count_num_t,
.web_order_typecls li.active .count_num_t {
    background: #8f0f33;
}

.ord-pagination-col select {
    width: auto;
    height: auto;
    padding: 1px 0 1px 1px;
    border: 0;
    margin: 0 5px;
    background: none;
}

.delivery_submit_div .button {
    width: 100%;
}

.mainacc_menulist #masterCount {
    background: #8f0f33;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 11px;
    line-height: 19px;
    vertical-align: top;
    margin-top: 1px;
    padding: 0 4px;
    min-width: 20px;
    font-family: 'avantgarde_bk_btbook';
}


.reward-list-body .delivery_total.delivery_total_number {
    padding: 0;
    margin: 0
}

.reward-list-body .delivery_total_left {
    display: inline-block;
    width: auto;
    margin: 0 15px 0 0;
}

.reward-list-body .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 17px
}

.reward-list-body .order_amt_div h3 {
    margin: 0;
    font-size: calc(15px + (22 - 15) * ((100vw - 300px) / (1600 - 300)));
    padding: 20px 0 0 0;
    text-align: center;
}

.reward-list-body .order_amt_div h3 sup {
    font-size: 75%
}

.reward-list-body .order_amt-right h3 {
    color: #fbaa1a;
}

.reward-list-body .checkoutDate,
.reward-list-body .checkoutTime {
    position: relative;
    padding: 0 0 0 22px;
    color: #474747 !important
}

.reward-list-body .checkoutDate:before {
    background: url(../images/calenderorder.svg) no-repeat;
}

.reward-list-body .checkoutTime:before {
    background: url(../images/clock-round.svg) no-repeat;
}

.reward-list-body .checkoutDate:before,
.reward-list-body .checkoutTime:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    content: "";
}

.reward-list-body .myacc_order_details .current_order {
    padding: 0 0 110px 0
}

.promo-form {
    text-align: center;
    padding: 40px 15px;
    margin: 0;
    background: #f0f0f0;
}

.promo_wrap .both-order-tab {
    padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promo-form input[type="text"] {
    margin: 0;
    height: 47px;
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
}

.promo-form .applyBtn {
    margin: 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    background: #000000;
    padding: 13px 20px;
    height: 47px;
    min-width: 146px;
}
.promo-form .applyBtn:hover{ 
   background: #ffa700;
}

.promo-form h3 {
    color: #6e1d24;
    font-size: 42px;
    margin: 0 0 15px;
    text-transform: uppercase;
    line-height: 1.3
}

.promo-form h3 span {
    font-size: 42px;
    display: block;
    font-family: 'the_secretregular';
    text-transform: capitalize;
    line-height: 0.5;
}


.promo-earned-col-image {position: relative;width: 50%;}
.promo-earned-col-image img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover;    max-height: 206px;
    object-position: center; }  
.promo-earned-col-item { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; border: 1px solid #fff; -webkit-box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); background: #fff;}
.promo-earned-info{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;padding: 30px 10px;width: 50%;-webkit-box-align: center;-ms-flex-align: center;align-items: center;    -webkit-box-pack: center;    -ms-flex-pack: center;    justify-content: center; text-align: center; position: relative;z-index: 1}
.promo-earned-info:before{  content: "";
    content: "";
    z-index: -1;
    position: absolute;
    top: -1px;
    left: -40px;
    bottom: -1px;
    background: #ffffff;
    width: 80px;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg); }
.promo-earned-code{  font-size: 17px;margin: 0 0 5px 0; }
.promo-earned-code span{display: block; font-size: 35px}
span.promo-valid { color: #a6a6a6;  font-size: 15px; padding: 0 0 15px 0;display: block;}
.promation_btn{line-height: 0}
.promation_btn .button{ margin: 0; background: #fbaa1a; -webkit-box-shadow: none; box-shadow: none; border: 1px solid #fbaa1a;border-radius: 4px; font-size: 15px; padding: 12px 18px 12px; color: #101010}
.promo-earned-info p{ font-size: 20px;  color: #4a4646; }
.promation_btn .promation_btn-one{ background: #f7f7f7; color: #000; border: 1px solid #d4d4d1; margin-right: 10px }
.promation_btn .button:hover{ background: #000; color: #fff; border-color: #000; }

.promo-bot-left span {
    display: block;
}

.promo-bot-left p {
    margin: 0;
    color: #000;
    text-transform: uppercase;
    font-size: 18px;
}

.promo-bot-left p span {
    display: block;
    font-size: 14px;
}

.promation_btn {
    line-height: 0
}


.promo-earned-col .tag {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    background: #000000;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 30px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    width: 220px;
    line-height: normal;
    text-align: center;
}

.promo-earned-col .tag span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mypromo-main-div .myacc_order_details {
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}
.mypromo-main-div .myacc_order_details .promo-earned-col{width: 49%; background: #fff;margin: 0 0 40px 0}


.mypromo-main-div .myacc_order_details li:nth-child(3n) {
    margin-right: 0
}

.pickup-cart-div .cart-direction:after {
    display: none
}

.pickup-cart-div .cart-direction img {
    width: 50px;
    margin: 0 auto
}

.pickup-cart-div .cart-header-first .row {
    display: block;
}

.pickup-cart-div .cart-header-first .cart_left {
    padding: 0 0 10px 0
}

.mobile-order-nowbtn {
    display: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}



span.myaccount_separate {
    width: 2px;
    height: 16px;
    display: inline-block;
    background: #fff;
    margin: 0 7px 0 5px;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 30px 0 0 0
}

.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px
}

.make-pay-div h4 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
}

.pay-cart-header {
    padding: 18px 0px 5px 0px !important;
}

.inner_banner {
    height: 400px;
}

.inner_banner img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

.err_alt {
    border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
    color: #ff1313;
}

.mfp-bg {
    background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 50;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #8F0F33;
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    line-height: 0;
    padding: 10px 14px;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 18px;
}

#scrollbutton a:hover {
    background: #ffdf00;
    color: #000;
}

.oreder-row-inv {
    margin: 0;
}

.oreder-row-inv .cart_img {
    padding: 0;
}

.oreder_itm_row {
    padding: 18px 5px 5px 5px !important;
}

.order-items-maindiv {
    background: #f5f5f5;
}
.Viewreceipt .order-items-maindiv{
    background: #fff;
}
.Viewreceipt .oreder_itm_row{
    padding: 10px 0 10px !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
        align-items: flex-start;
}
.Viewreceipt .cart_price, .thank-order-detaildiv .cart_price{ padding-right: 0 }
.Viewreceipt .tnk-delivery .delivery_total_number{
    border-bottom: 1px solid #e4e4e4;
        padding-bottom: 20px;
    margin-bottom: 10px;
}
.oreder-row-inv .cart_left {
    text-align: left;
}

.month-select-div, .choose-date-div {
    margin: 0 auto 40px auto;
}

.tarms-error {
    display: none;
    color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
    display: block;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
      font-family: 'avantgarde_bk_btdemi';
}

.catering-product-rowlist .price_nor {
    font-size: 22px;
    display: block;
    padding: 10px 0 0 0;
        font-family: 'avantgarde_bk_btbook';
    font-weight: bold;
}

.catering-product-rowlist .price_nor sup {
    font-size: 80%;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
}

.dotted-line-top {
    border-top: 1px dotted #ccc;
    padding: 20px 0 0 0;
    margin: 10px 0 0 0;
}

.custom-tarms-div .sign_reg {
    padding-left: 30px
}

.custom-tarms-div span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #ffa700;
    margin-top: 2px;
    border-radius: 0;
}

.custom-tarms-div .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\F00C";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #ffa700;
    border: 2px solid #ffa700;
}

.error_div_cls {
    color: red;
    display: none
}

.single_outlet_info {
    padding-top: 15px;
    text-align: center;
}

.single_outlet_info p {
    margin: 0;
    font-size: 16px;
}

.single_outlet_info .mainacc_toptext p {
    font-size: 20px;
    font-style: normal;
}

.isDisabled {
    cursor: default;
    text-decoration: none;
}

.disableDivCls {
    display: none;
}

.event-type-single {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rmv-border-bottom {
    border-bottom: 0px !important;
}

.no-stock-product .innerproduct-item::after {
    opacity: 1 !important;
}

.no-stock-product .innerproduct-button {
    opacity: 1 !important;
    visibility: visible !important;
    top: 50% !important;
}

.no-stock-product .innerproduct-button .btn {
    cursor: not-allowed;
    pointer-events: all !important;
}

.guest_btn {
    text-align: center;
}

.controls.single-link {
    text-align: center;
}

.controls.single-link a {
    font-size: 15px;
    display: inline-block;
    color: #272727;
        font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
}

.controls.single-link a:hover{ color: #fbaa1a; }

.back-to-listbtn a {
    width: 100% !important;
}

.apply-red {
    color: red!important;
}

.pdpa_trmcont_link {
    font-size: 14px;
}
.pdpa_trmcont_link span{
    display: inline-block
}

.delivery_outletpoup .self_popup_hea_row { background: #8F0F33; }
.delivery_outletpoup .outlet-scooter-img { width: 86px; }

.outleterror { color: #ffa700; }
.delete-act-icon {
	position: absolute;
    right: 2px;
    top: 0px;
}
.act-remove-topdiv {
	position: relative;
	padding-right: 20px;
}

.venue_popup .close-icon-but {background: #98684a;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;}
.hide_section_cls, .back_click_mbl{display: none}
.back_click_mbl a:before {
    content: "\f104";
    font-family: FontAwesome;
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;
}
.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 450px;
    margin: auto !important;
    padding: 30px;
    font-size: 22px;
    border-radius: 10px;

    display: inline-block;
    text-align: center;
}
.custom_center_alertcls.alert-danger{     
    color: #ca322f;
    background-color: #fdd1d1;}
.mfp-custom-close {
    position: absolute;
}
img.view-img-span {
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
img.close-img-span {
    width: 19px;
    position: relative;
    top: 1px;
    margin-left: 5px;
    display: none;
}
.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}
.text-lbl {
    display: block;
    font-weight:bold;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li{ margin-right: 0 }
.price_nor dl {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
}
.ordrdatetime_error .error { width: 100%; }
.syd_merge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.syd_date {  width: 48%;}
.syd_time.delivery_submit_cls {  width: 48%;}
.syd_merge .form-group {margin: 0}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container {
	display: block;
}

.membership_status {
margin-top: -33px;
position: relative;
}

.membership_status span {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    text-transform: uppercase;
    font-family: 'avantgarde_bk_btdemi';
    font-size: 18px;
}


/*myaccount - 13 jan 2020*/
.membership-desc { background-color: #ffa700; border: 2px solid #fff; padding: 12px 5px 10px; font-size: 20px; text-transform: uppercase; font-family: 'avantgarde_bk_btdemi'; margin: 15px auto; max-width: 250px; }
.membership-desc img{ width: 20px; margin-right: 8px; }
.user-id{ margin: 10px 0; }
.user-id span {text-transform: uppercase; font-weight:bold; }
.membership-progress {background: #4f7873; margin: 15px -10px 0; padding: 20px 0 15px; }
.membership-progress p {font-weight:bold; margin: 9px auto 0; max-width: 170px; line-height: 1.2; }
.pg-bar{ display: block; position: relative; width: 100%; height: 15px; border-radius: 25px; background-color: #2a2521; max-width: 175px; margin: 0 auto;}
.pg-bar span{ position: absolute; height: 100%; width: 0%; background-color: #fff; top: 0; left: 0; border-radius: 25px; -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3); box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);}
.member-discount-desc { display: inline-block;background: #ffa700;border-radius: 10px;padding: 3px 10px;font-size: 12px;color: #fff;margin: 5px 0;line-height: 1;text-align: center; }
.member-discount-total {color: #98684a; font-weight:bold; font-size: 14px; }


.product-tag-list ul {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-tag-list ul li {
    margin: 0 5px;
}
.product-tag-list ul li img{max-width: 16px; max-height: 16px;}


.products-image-div, .det_inner_image{height: 137px;  display: -webkit-box; display: -ms-flexbox; display: flex; --webkit-box-pack: center; -ms-flex-pack: center; -webkit-box-pack: center; justify-content: center; -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end }
.product-info-div, .det_inner_content{padding:15px 12px 12px 12px ;    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: calc(100% - 144px);}
.det_inner_content h5 {text-transform: inherit;color: #353535;}
.product-title-maindiv {
    position: relative;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
}
.product-title h5 {
    margin-bottom: 10px;
}

.product-short-description{ 
    color: #353535;
    font-size: 14px;
    margin: 0 0 25px 0;
    line-height: 1.3;
        flex: 1 1 auto;
}
.product-price h3{margin: 0; font-size: 22px;}
.product-price h3 sup{ font-size: 70%}
.products-ordernow-action .button{margin-bottom: 0;
    margin-top: 0;
    font-family: 'avantgarde_bk_btdemi';
    font-size: 15px;
    letter-spacing: 0.35px;
    padding: 14px 15px 14px;
    min-width: 128px; background: #8f0f33; }
.products-ordernow-action .button:hover{background: #ffdf00;color: #000;}
.products-ordernow-action{margin: 20px 0 0 0;}
.product-short-description p {
    width: 100%;
    max-width: 100%;
    margin: 0;
}
.products-single-li{position: relative; z-index: 2; overflow: hidden;}

.featured-products-section h2{text-align: center}
.products-ordernow-action .button.disabled, .products-ordernow-action .button.disabled:hover{ opacity: .4; cursor: not-allowed; -webkit-box-shadow: none; pointer-events: none; }
.hmenu-login-section ul {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.doorstep-block-section{position: relative}

.guest-checkout-popupdiv {
    background: #ededed;
    margin: 0 -55px;
    padding: 25px 55px;
}
.guest-checkout-popupdiv .button {
    margin: 0;
    background: #464646;
    display: block;
    width: 100%;
    padding: 17px 5px;
    font-family: 'avantgarde_bk_btdemi';
}
.signup-gender-selct {
    margin-bottom: 0;
}
span.birthday_info_sp {
    font-size: 12px;
    color: #737373;
}

span.birthday_info_sp b {
    font-weight: 500;
    font-style: italic;
}
.product-menu-listing {padding: 10px 0 10px 0;margin-bottom: 50px;}
.product-menu-listing .container {display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;align-items: flex-start;max-width: 1280px;}
.product-menu-listing .container:before, .product-menu-listing .container:after{ display: none;}
.delivery-list .product-lhs {background: #fff;margin: 15px 0 0;}
.delivery-list .product-rhs {margin: 15px 0 0;}
.product-lhs { width: calc( 100% - 388px ); box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14); padding: 20px 0 0 0 }
.product-rhs{width: 374px; position: relative} 
.product-rhs .hcart_dropdown .cart_body,
.product-rhs .hcart_dropdown .cart_footer {/*background: #fff;*/ background: #FCF5D5;}
.product-rhs > ul{list-style: none; padding: 0;}
.productlist-main-div .products-list-ulmain, .product_det_cards > ul{ display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;padding: 0;list-style: none}
.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card {/*border: 2px solid #fff;*/ border: 2px solid #FCF5D5; text-align: center; width: 32%; margin: 0 2% 20px 0; /*background: #fff;*/background: #FCF5D5;     -webkit-box-shadow: 0px 3px 11px 0px rgba(1, 1, 1, 0.14);     box-shadow: 0px 3px 11px 0px rgba(1, 1, 1, 0.14); transition: 0.4s linear all }
.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card:hover {border: 2px solid #8F0F33;background: #F9F9F9;}
.productlist-main-div .products-list-ulmain > li:nth-child(3n), .product_det_cards > ul li.det_card:nth-child(3n){margin-right: 0}


.menu-section-left {
    border-bottom: 4px solid #e9e9e9;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
}
.menu-section-left-inner .menu-list-intvl{ width: 25%;text-align: center; }
.menu-section-left-inner .menu-list-intvl a{ padding: 10px 2px; position: relative;cursor: pointer; color: #0a0b10; font-family: 'avantgarde_bk_btdemi';text-transform: capitalize; font-size:17px;line-height: normal; display: block;     height: 100%;}
.menu-section-left-inner {
    width: calc( 100% - 20% );
    display: flex;
}

.more-menu {
    width: 9%;
    margin: 0 0 5px;
}
ul.more_categor_info {
    padding: 0;
    list-style: none;
    min-width: 200px;
    max-width: 320px;
   /* background: #fff;*/
    background: #FCF5D5;
    position: absolute;
    right: 0;
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 3px;
    top: 100%;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 1px 2px 3px #00000040;
}
.menu-section-catering.menu-section-left ul.more_categor_info .menu-list-intvl {padding: 5px 0;}
.menu-section-catering.menu-section-left ul.more_categor_info .menu-list-intvl a {
    padding: 5px 20px;
    border-bottom: 1px solid #f3f3f3;
    color: #0a0b10; font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    display: block;
}
.menu-section-catering.menu-section-left ul.more_categor_info .menu-list-intvl a:hover {color: #8f0f33;}



ul.more_categor_info li a{ display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #f3f3f3;
    color: #0a0b10; font-family: 'avantgarde_bk_btdemi';
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1;
    color: #000;}
ul.more_categor_info li:last-child a{ 
 border-bottom: 0;
}
ul.more_categor_info li a:hover, ul.more_categor_info li.active a{ color: #8F0F33 }
.more-menu-parent {
    cursor: pointer;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
    color: #0a0b10;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: capitalize;
    font-size: 16px;
    line-height: normal;
    height: 100%;
    margin: 0 -10px 0 0;
}
label.open_more_category {
    width: 75px;
    text-align: center;
    cursor: pointer;
    font-size: 25px;
    color: #000;
    background: #FCF5D5;
    position: relative;
    border-radius: 5px;
    display: flex;
    right: 10px;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.search-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    width: 100%;    padding-bottom: 10px;
    padding-top: 30px;
}
.search-product > .container {display: flex; flex-wrap: wrap;justify-content: space-between;max-width: 1280px;}
.search-product > .container:before, .search-product > .container:after, .menu-top-section-inner .container:before, .menu-top-section-inner .container:after{ display: none}

.spm-lhs{position: relative;}
.spm-lhs .search-rest{padding-right: 45px; border: 0; box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07);color: #626262;font-family: 'CenturyGothic';font-size: 15px; height: 52px;padding-left: 18px;    font-size: 17px;  margin-bottom: 0}
.spm-lhs img{
    position: absolute;
    right: 15px;
    top: 15px;
    width: 21px;
}
.spm-lhs{width: 380px;}
.spm-rhs{width: 162px;}
.spm-rhs .filter-area{ padding-right: 45px;
    border: 0;
    box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07);
    height: 52px;
    padding-left: 18px;
    font-family: 'CenturyGothic';
    font-size: 15px;
    padding: 14px 40px 12px 18px;
    position: relative;width: 100%;background: #fff; cursor: pointer; cursor: pointer;}

.spm-rhs {
    width: 162px; margin-left: 10px;}

.filter-area img{
    position: absolute;
    right: 15px;
    top: 18px;
    width: 17px;
}
.filter-area img.filter-clear-image{
    width: 12px;
    top: 20px;
}
.filter-details {
    position: absolute;
    z-index: 9;
    width: 100%;
    left: 0;
    top:105%;
    display: none;
    max-height: 600px;
    overflow-y: auto;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.10);
    background: #fff;
    padding: 20px;
}
.filter-details.active {display: block}
.common-filter{padding-bottom: 20px;}
.common-filter:last-child{padding-bottom: 0}
.common-filter ul{padding: 0; list-style: none;    display: flex;  flex-wrap: wrap;}
.common-filter ul li{ width: 23.828125%; margin:0px 1.5625% 15px 0; cursor: pointer;line-height: 20px}
.common-filter ul li:nth-child(4n){margin-right: 0}
.common-filter h3{ font-size: 17px;margin: 0 0 15px 0;}
.common-filter.item-offer ul li{
    display: block;
    font-weight: 700;
    border:2px solid #d3334b;
    border-radius: 5px;
    padding: 10px 5px;
    line-height: 1;
    text-align: center;
}
.common-filter ul .custom_checkbox span{ padding-left: 25px }
.common-filter ul .custom_checkbox span img{    vertical-align: text-bottom;margin-right: 3px}

.clear-filter {
    position: absolute;
    right: 20px;
    top: 25px;
    line-height: 1;
    font-size: 12px;
}

.backto-rest{box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.10); background: #fff;
    margin-right: 6px; text-align: center }
.backto-rest a{  display: block;  padding: 10px;display: flex;
    align-items: center;
    justify-content: center;height: 100%; width: 100%; background: #fff; transition: 0.3s linear all;}
.backto-rest a img{width: 28px;}
.backto-rest a:hover { background: #8F0F33;}
.backto-rest a:hover img {filter: brightness(0) invert(1);-webkit-filter: brightness(0) invert(1);}
.search-product-main{ display: flex; flex-wrap: wrap;position: relative;}


.product-lo-top a {
    display: inline-block;
    padding: 13px 20px;
        font-family: 'avantgarde_bk_btdemi';
    font-size: 16px;
    color: #b4b3b3;
    line-height: 1.3;
    text-transform: uppercase;
    background: #e7e6e6;
    min-height: 46px;
    position: relative;
    width: calc(50% - 11px);
    text-align: center;
}
.product-lo-top a:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    float: left;
    padding-right: 1px;
}
.product-lo-top a:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    float: right;
    padding-left: 1px;
}

.product-lo-top a:first-child:after, .product-lo-top a:last-child:before {
    content:'';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
}
.product-lo-top a:first-child:after {
    left: 100%;
    border-top: 46px solid #e7e6e6;
    border-right: 23px solid transparent;
}
.product-lo-top a:last-child:before {
    right: 100%;
    border-bottom: 46px solid #e7e6e6;
    border-left: 23px solid transparent;
}

.product-lo-top a.active{
    color: #fff;
    background: #8f0f33;
}
.product-lo-top a.active:last-child:before{ border-bottom-color: #8f0f33; }
.product-lo-top a.active:first-child:after{    border-top-color: #8f0f33;}
.product-lo-top { min-width: 330px;}



.progress_bar_div{ margin-top: 10px}
.common-inner-blckdiv {margin-bottom: 50px;}
.common-inner-banner{ position: relative; padding: 20px 10px 30px; color: #000; display: -webkit-box; display: -ms-flexbox; display: flex;  -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap;justify-content: center; background:url(../images/inner-banner.png) no-repeat;background-position: center; background-size: cover; min-height: 136px; text-align: center; } 
.common-inner-banner h1,
.common-inner-banner p {margin: 0;font-family: 'Poppins', sans-serif;font-size: calc(17px + (59 - 17) * ((100vw - 300px) / (1600 - 300)));text-transform: capitalize;line-height: 1;color: #fff;font-weight: 700;flex: 1 1 100%;}
.common-inner-banner span{font-family: 'Poppins', sans-serif;font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));color: #fff;}
.menu-section-inner{max-height: 500px; overflow: auto;}
.fxd_lftmenu_div{position: fixed; top: 160px; width: 300px;}
.bottom_abs {  position: absolute; top: auto; bottom: 0; width: 300px;}

.prodet_top{text-align: center}
.prodet_top img{margin: 0 auto}
.prodetailinner-main-div .modal-body{ padding: 0; }
.detail-pg-slider .slick-track .slick-slide > div {
    margin: 30px 7px;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
    box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
    width: 100%;
    text-align: center;
        overflow: hidden;
}
.detail-pg-slider .product-title h3{font-size: 21px; margin: 0;}
.addonpro-slider-top{ margin: 70px 0 0px 0}
.chk-payment-title h3, .chk-addonpro-title h3{font-size: 24px;text-transform: uppercase; text-align: center}
.detail-pg-slider .product-tag-list{bottom: -8px;}
.chk-addonpro-title h3{margin-bottom: 0px}
.addcart_row:after { clear: both; content: ""; display: table;overflow: hidden;}
.birthday-info-div{padding: 25px 5px 0px 5px; text-align: center;}
.birthday-info-top h3{color: #fff;font-size: 43px;text-transform: uppercase;margin-bottom: 0; padding-top: 10px; line-height: 1; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.birthday-info-top h3 img {margin: 0px 0 0 15px; position: relative; top: -5px;}
.birthday-info-middle{ font-family: 'avantgarde_bk_btbook'; padding: 10px 0 0 0; font-size:16px; color: #000 }

.birthday-info-middle p{ color: #fff }

.left-input-div, .right-input-div {max-width: 280px;width: 100%;}
.birthday-inpt-act {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;
justify-content: space-between;-ms-flex-wrap: wrap;flex-wrap: wrap;max-width: 570px;margin: 0 auto;}
.narml_select { position: relative;}
.narml_select:after {  width: 30px; height: 46px; content: ""; position: absolute; right: 1px; background: #fff;    top: 1px;}

.normal-popup {
    max-width: 680px;
    text-align: center;
}
.normal_popup_cont ul{padding: 0; list-style: none}
.normal-popup button.mfp-close{background:#ffa700;
opacity: 1;
color:#fff;
border-radius: 2px;
width: 30px;
height: 30px;
line-height: 1;}

.promopopup-maindiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#promo-check-popup {
  max-width: 810px;
  background: none;
}
.promopopup-rhs {
  width: 53.08641975308642%;
  border: 1px solid #ffa700;    
}
.promopopup-lhs {
  width: 46.91358024691358%;
  border-top: 30px solid rgba(0,0,0,0);
  border-bottom: 30px solid rgba(0,0,0,0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.frm-action-div{   background: #ffa700; color: #fff;  padding:40px 30px; }
.promopopup-rhs img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }
.promopopup-lhs .frm-top-title h3{ color: #fff; margin-bottom:0px; }
.promopopup-lhs .frm-inputbtn-div { position: relative;}
.promopopup-lhs .frm-inputbtn-div .button {
position: absolute;
right: 0;
top: 0;
margin: 0;
height: 48px;
background:
#000;
font-size: 15px;
padding: 10px 14px 10px;
font-family: 'avantgarde_bk_btbook';
font-weight: bold;
border: 1px solid #fff;    
}
.promopopup-lhs .form-group input{border-color: #fff;padding-right: 110px;}
.promopopup-lhs .frm-bottom-text{ text-transform: uppercase; font-size: 14px; font-weight: bold;margin-bottom: 0}
.promopopup-lhs .frm-bottom-text p{margin: 0}
.promopopup-lhs .form-group{margin-bottom: 5px;}
#promomail-error { color:  #b70000; line-height: 1; font-size: 13px}
.promo-check-popup button.mfp-close{background:#ffa700;
opacity: 1;
color:#fff;
border-radius: 6px;
width: 30px;
height: 30px;
line-height: 1;
top: -15px;
right: -15px;}

.prodetailinner-main-div button.close {
    width: 24px;
    height: 24px;
    background: #000;
    color: #fff;
    box-shadow: none;
    top: 5px;
    right: 5px;
        text-indent: 9999px;
    position: absolute;
}
.prodetailinner-main-div button.close:after{ 
 background: url(../images/plus-white.svg) no-repeat;
 width: 16px;
height: 16px;
content: "";
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
    -webkit-transform: rotate(45deg);   
    transform: rotate(45deg);    
}
#ProductDetailMdl .modal-content{ border-radius: 0 }

@media (max-width: 1300px){
	.menu-section-innerr{max-height: 430px;}
}
@media (min-width: 1400px){
	.menu-section-inner{        max-height: 530px;}
}
@media (min-width: 1601px){
	.menu-section-inner{       max-height: 665px;}
}
@media (min-width: 1850px){
	.menu-section-inner{    max-height: 788px;}
}

.mbl-hiden{overflow-y: hidden}

.mobile-order-nowbtn a {
    padding: 15px;
    background: #8F0F33;
    font-size: 16px;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    font-family: 'avantgarde_bk_btdemi';
    display: block;
    color: #fff;
    letter-spacing: 0.5px;
}
.mobile-order-nowbtn a:hover {background: #F5D52B;color: #000;}
.mobile-login{display: none}
.mobile-login ul {
    padding: 0;
    list-style: none;
    position: absolute;
    top: 110%;
    left: 0;
    background: #8f0f33;
    border-radius: 6px;
    min-width: 150px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 5;
    display: flex;
    flex-flow: row wrap;
    
}
.mobile-login ul.active{
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

.mobile-login {
    position: relative;
}

.mobile-login ul li a {
    display: block;
    line-height: 1;
    padding: 10px 20px;
    color: #fff;
    border-bottom: 1px solid rgba(255,255,255,0.22);
}
a.controller-nav img {
    width: 32px;
}

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background-color: #8F0F33;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 99999;
    max-width: 520px
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px
}

.mobile-menu .menu li {
    list-style: none;
    position: relative
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 50px 40px 40px;
    height: 100%
}

.mobile-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mobile-menu.active .menu a {
    -webkit-animation: a .4s .2s backwards;
    animation: a .4s .2s backwards
}

.mobile-menu.active .menu li:nth-of-type(2) a {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.mobile-menu.active .menu li:nth-of-type(3) a {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.mobile-menu.active .menu li:nth-of-type(4) a {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.mobile-menu.active .menu li:nth-of-type(5) a {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}
.mobile-menu.active .menu li:nth-of-type(6) a {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}
.mobile-menu.active .menu li:nth-of-type(7) a {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}

.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}

.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 22px;
    height: 22px;
    position: relative
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg)
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 24px;
    z-index: 2;
    content: ""
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mobile-menu .mobile-menu-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 50px;
    background: #8f0f33;
    z-index: 2;
    text-align: right;
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
}

.mobile-menu .menu a {
    display: block;
    font-size: 17px;
    color: #fff ;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    font-family: 'avantgarde_bk_btdemi';
    padding: 1.3rem 0;
    line-height: 1;
    text-transform: uppercase;
        letter-spacing: 1px;
}

.mobile-menu .menu a:hover {
    color: #000 ;
}

.mobile-menu .menu .sub-menu {
    display: none;
    padding: 0
}

.mobile-menu .menu .sub-menu li {
    padding-right: 0
}

.mobile-menu .menu .sub-menu li:last-child {
    border-bottom: 0
}

.mobile-menu .menu .sub-menu a {
    font-family: 'avantgarde_bk_btbook';
}

.mobile-menu .menu li {
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
    padding-right: 40px
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
.pkselect_tabsec .tab-content {
    background: #f9f7f8;
    padding: 0px 25px 20px;
}
.rest-list-cls { 
	margin-top: 20px;
}
.li-full-width {
	width: 100% !important;;
}
.btn-act-clss { 
    color: #fff;
    background: #000;
	border: 1px solid #ffa700;
}

#delivery-continue-link, #takeaway-continue-link {
	display : none;
}


/* omise */
.omise-form-error input {
    -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    border-color: #ff7076;
    border-top-color: #ff5c61;
}

.pay-omiseconf-popup { max-width: 340px; border-radius: 10px;background: #f5f5f7}
span.omise-pay-img {
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
}

img.makisan-logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
}

.omise_pay_header {
    position: relative;
    padding: 50px 30px 25px;
    text-align: center;
    border-bottom: 1px solid #d2d2d3
}
.omise_pay_body {
    padding: 30px;
    position: relative;
        border-top: 2px solid #fff;
}
.omisepop_in .form-control{ padding: 10px; height: 40px; font-size: 14px;border-radius: 4px; padding-left: 36px}
.expire_row_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.expire_row_inner > div {
    width: 32%;
    position: relative;
}
.omisepop_in .alt_btns .button {margin-bottom: 0;
    width: 100%;
    padding: 13px 10px 11px;
    border-radius: 4px;}
.omisepop_in .form_sec i{ position: absolute; left: 10px; color: #888;top:12px;  }
.omisepop_in .form_sec > div{ position: relative }
.omisepop_in .form_sec .uname-box  i{ left: 13px;}
.omisepop_in .form_sec .card-numbr-div i, .omisepop_in .form_sec .expire_left i {font-size: 16px;top:13px;}
.omisepop_in .form_sec .expire_right  i{ font-size: 19px; top: 12px;}
.expire_mdl .form-control{ padding-left: 15px }
.card {
    position: absolute;
    display: block;
    right: 10px;
    width: 28px;
    height: 19px;
    background: url(../images/cards/spritecard.png) no-repeat 0;
    top: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    background-position: 0 0;
    overflow: hidden;
}
.visa + .card {
    background-position: 0 -19px;
}
.master+ .card {
    background-position: 0 -38px;
}
.discover + .card {
    background-position: 0 -57px;
}
.american + .card {
    background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
    padding: 0;
    width: 21px;
    height: 21px;
    display: block;
    color: #fff !important;
    border-radius: 50%;
    line-height: 21px;
    background: #a5a5a5;
    border: 1px solid #929292;
    top: 10px;
    font-size: 20px;
    right: 10px;
    text-align: center;
}
.pay-omiseconf-popup button.mfp-close:hover{ background: #000; border-color: #000; }
.omisepay-mode-option {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #ffd025;
    line-height: 1;
    padding:9px 11px 8px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}
.omise-error-info-div {
	color: #ffffff;
    background-color: #b12f2f;
    border-color: #ebccd1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
}
.omise-error-icon { font-size: 14px; }
.omise_alert_close {
	position: absolute;
    right: 0px;
    color: #FFF;
    top: 0;
}
.omise-error-msg {
	margin: 0 0 0 11px;
    font-size: 14px;
}
.omise-error-info-div .container {
	display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}
.displayDivCls { display: none; }
.would-like-bottom label.chk_hea {
    margin: 0;
    padding: 0 0 0 35px;
    font-size: 15px;
    text-transform: uppercase;
}

.would-like-bottom .custom_checkbox {
    left: 0;
    right: inherit;
    top: -4px;
}

.would-like-bottom {
    margin: 15px 0 10px;
}
.cart_row.promo-cart-row {
    position: relative;
    padding-right: 15px;
}
.product_sidebar_top{display: none}
.product-rhs .datetime-wrap .react-datepicker-wrapper{display: block}
.product-lhs-inner{/*background:#fff;*/ background: #FCF5D5; box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14); padding: 10px 0 0 0; overflow: hidden;}
.catering-product-list .product-lhs {box-shadow: none;  padding: 0;margin: 15px 0 0;}
.catering-product-list .product-rhs {margin: 15px 0 0;}
.menu-section-catering.menu-section-left {border-bottom: none;width: 100%;}
.menu-section-catering.menu-section-left .menu-section-left-inner {width: calc(100% - 9%);}
.products_sidebar_inner ul,
.menu-section-catering.menu-section-left .menu-section-left-inner {margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap}
.products_sidebar_inner ul li { width: 24.49%;min-height: 56px;margin: 0 0.5% 5px 0;}
.menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl {width: 30%;margin: 0 0.5% 5px 0;}
.menu-section-catering.menu-section-left .more-menu {width: 20%;}
.menu-section-catering.menu-section-left label.open_more_category {background: transparent;height: auto;}
.menu-section-catering.menu-section-left .more-menu-parent {
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.menu-section-catering.menu-section-left ul.more_categor_info {padding: 15px;}
.products_sidebar_inner ul li a, .slick-slider .products_sidebar_content a,
.menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link {color: #060801;background: #FCF5D5;padding: 14px; text-align: center; display: flex; justify-content: center; align-items: center;text-transform: capitalize;font-family: 'Poppins', sans-serif;font-size: 17px;transition: 0.3s linear all; height: 100%;border-radius: 5px; }
.menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link::before {display: none;}
.products_sidebar_inner ul li:nth-child(4n){ margin-right: 0 }
.products_sidebar_inner ul li:hover a, .products_sidebar_inner ul li.active a, .slick-slider .products_sidebar_content a:hover, .slick-slider .products_sidebar_content.active a {color: #FFFFFF;background: #8F0F33; }
.menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link:hover,
.menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl.active .menu-title-link { color: #FFFFFF; background: #8F0F33}

.product-lhs-inner .products_list{ padding: 25px 18px 10px 18px;  }
.products_sidebar_inner {
    margin-bottom: 15px;
}
.products_list_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.products_list_inner{width: 49%;}
.products_list_desc{width: 49%;}
body .accordion{border: 0; border-radius: 0 }
.product-lhs-inner .products_sidebar_inner ul, .menu-section-catering.menu-section-left .menu-section-left-inner {width: 80%;display: flex;}

.product-lhs-inner .menu-section-left {
    border-bottom: 4px solid #e9e9e9;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
}
.product-lhs-inner .menu-section-left-inner .menu-list-intvl{ width: 25%;text-align: center; }
.menu-section-left-inner .menu-list-intvl a{ padding: 10px 2px; position: relative;cursor: pointer; color: #0a0b10; font-family: 'avantgarde_bk_btdemi';text-transform: capitalize; font-size:17px;line-height: normal; display: block;     height: 100%;}


.product-lhs-inner .menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link::before,
.menu-section-catering.menu-section-left .more-menu-parent::before {
    content: "";
    display: block;
    position: absolute;
    left: 51%;
    right: 51%;
    bottom:-9px;
    background: #8f0f33;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;   
}
.menu-section-catering.menu-section-left .more-menu-parent.currect_active::before { left: 0; right: 0; }
.product-lhs-inner .menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link {background: transparent;color: #000;font-weight: 700;font-size: 16px;}
.product-lhs-inner .menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link:hover::before, .product-lhs-inner .menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl.active .menu-title-link::before { left: 0; right: 0; }
.product-lhs-inner .menu-section-left-inner {
    width: calc( 100% - 20% );
    display: flex;
}

.product_acc_grp .accordion .accordion_title{ background: #282828; color: #fff; font-family: 'avantgarde_bk_btdemi';padding: 12px 35px 10px 12px;  line-height: 1; text-transform: capitalize;cursor: pointer;position: relative;    display: flex;  justify-content: space-between;}
.product_acc_grp .accordion{margin: 0 0 10px 0}
.product_acc_grp .accordion .accordion_title span{ color: #ece223;font-family: 'Open Sans', sans-serif;font-size: 90%;}
.product_acc_grp .accordion .accordion_title::before, .product_acc_grp .accordion .accordion_title::after {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right:10px;
    top: 50%;
    transition: transform 0.25s ease-in-out 0s;
    -webkit-transition: transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}
.product_acc_grp .accordion .accordion_title::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.product_acc_grp .accordion .accordion_title::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.product_acc_grp .accordion .accordion_title.active::before{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.product_acc_grp .accordion .accordion_title.active::after{
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

a.button.add_to_cart {
    width: 100%;
}

.menu-section-mobile-inner .slick-track, .mobile-menu-section-left .slick-track{
    border-bottom: 4px solid #e7e6e6;
}
.menu-section-mobile-inner .slick-track .slick-slide > div, .mobile-menu-section-left .slick-track .slick-slide > div{display: block; width: 100%;}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #c3c1c1;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;text-align: center}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #8e0f33;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link:before{    left: 0; right: 0;}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link{color: #181818}
.menu-section-mobile-inner, .mobile-menu-section-left{display: none;position: relative;width: 100%;background: #fff;}
header.small_sticky {
    position: fixed;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.08);
}
.slick-slide .menu-list-intvl, .products_sidebar_inner .slick-slide {
    height: 100%;
}
.products_sidebar_inner .slick-track .slick-slide > div {
    display: block;
    width: 100%;
}
.menu-section-mobile-inner button.slick-arrow, .products_sidebar_inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow{
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s linear all;
}
.menu-section-mobile-inner button.slick-arrow:hover, .products_sidebar_inner button.slick-arrow:hover, .mobile-menu-section-left button.slick-arrow:hover {background: #000;}
.menu-section-mobile-inner .slick-prev, .products_sidebar_inner .slick-prev, .mobile-menu-section-left .slick-prev{left: 5px;}
.menu-section-mobile-inner .slick-next, .products_sidebar_inner .slick-next, .mobile-menu-section-left .slick-next{right: 5px;}
.menu-section-mobile-inner button.slick-arrow:before, .products_sidebar_inner button.slick-arrow:before, .mobile-menu-section-left button.slick-arrow:before{ position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px; background: url(../images/left-arrow.png) no-repeat; content: "";
    margin-left: 6px;}
.menu-section-mobile-inner button.slick-arrow.slick-next:before, .products_sidebar_inner button.slick-arrow.slick-next:before, .mobile-menu-section-left button.slick-arrow.slick-next:before{
    transform: rotate(180deg);
    margin-top: -6px;
}

.hcart_dropdown .cart_left { width: calc( 100% - 120px );}
.hcart_dropdown .cart_right{ width: 115px;}
.product_orders_top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #302d2a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.product_orders_top h4 {
    color: #fff;
    margin: 0;
    font-size: 18px;
}

span.close_product_orders {
    line-height: 1;
    cursor: pointer;
}
header .hcart_dropdown .hcart_scrollarea {
    overflow-y: auto;
    height: 100%;
}
header .hcart_dropdown .cartaction_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
header .hcart_dropdown .cartaction_bottom .btn{border-radius: 0;}
header .hcart_dropdown .cart_footer { padding: 20px 20px 0;}
.product_card_desc {
    text-align: center;
    padding: 10px;
    font-style: italic;
}
.product-rhs .hcart_dropdown .hcart_scrollarea {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.cart_footer_inner_item {width: 100%;}
.product_card_desc p{margin-bottom: 5px;}
.package_checkitem{text-align: left;font-size: 14px;}
.package_checkitem .custom_checkbox{margin: 0 0 10px 0;display: block}
.package_checkitem .custom_checkbox span{ padding: 0 50px 0 0;color: #353535;  line-height: 1.2;font-family:'Helvetica'; font-size: 14px; display: block }
.package_checkitem .custom_checkbox span:before{right: 0; left: inherit;    border-radius: 5px;}
.package_checkitem .custom_checkbox input[type=checkbox]:checked + span:before{ background: url(../images/tick20-square.png) no-repeat;background-position: center;}
.package_checkitem .custom_checkbox span p{margin: 5px 0 0 0;}
.package_checkitem .custom_checkbox span img { position: absolute; right: 30px;}
.input-file-sec{ position: relative;margin: 10px 0 0 0}
.input-file-sec #upload-file {position: absolute; opacity: 0; width: 100%; height: 100%; right: 0; cursor: pointer;}
.file-name {display: inline-block; vertical-align: middle; box-shadow: none; border-radius: 4px; margin: 0; margin-right: 10px; }
.input-file-sec .btn {pointer-events: none;padding: 9px 20px; font-size: 14px; }
.product_det_cards .det_inner_content i {font-family: 'Helvetica';font-weight: bold;}
.show_details > label.combo-input{ display: none;}
.det_card .pkgerror_txt {
    color: #f90000;
    font-size: 13px;
    margin: 0 0 5px 0;
}
.pkgerror_txt {
    color: #f90000;
    font-size: 13px;
    margin: 0 0 5px 0;
}

.package_details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 22px;
}

.package_details_left {
    width: 48%;
}

.package_details_right {
    width: 50%;
    margin: 25px 0 0;
}
.package_details_left .slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex !important;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    justify-content: center;
}
.package_details_left .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 11px;
    height: 11px;
    color: transparent;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0 3px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #302d2a;
}
.package_details_left .slick-dots li.slick-active button{ background: #fff }
.package_details_left .slick-slide img{ max-height: 240px; width: 100%; object-fit: contain; }
.pdr_right {
    text-align: center;
    width: 150px;
    
}
.pdr_right-inner{ display: flex; flex-flow: column wrap; align-items: center; justify-content: center; }

.package_details_right_inner {
    display: flex;
    justify-content: space-between;
}

.pdr_left {
    width: calc(100% - 160px);
    font-size: 14px;
}
.pdr_left p {
    font-family: 'Helvetica';
    font-size: 14px;
    color: #777777;
}
.pdr_left h4{    
    margin: 0 0 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #060801;
    text-transform: capitalize;}

.pdr_right .qty_bx{width: 120px; height: 38px;    padding: 0 30px;}
.pdr_right .product-min-pax {font-family: 'avantgarde_bk_btdemi';font-size: 13px; margin: 8px 0 12px;color: #666666;}
.pdr_right h5{
    font-family: 'avantgarde_bk_btbook';
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 5px;
    color: #353535;
}
.pdr_right .proqty_input{
    height: 100%;
    line-height: 36px;
    font-size: 17px;
    border-right: 2px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 2px solid #e0e0e0;
}
.pdr_right .qty_bx span{width: 30px}
.pdr_right h4{ 
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #060801;
    text-transform: capitalize;
}
.pdr_right h4 sup{top: -1px; margin-right: 2px;font-size: 90%;}
.fix-only-lhs{ display: none}
.fix-only-lhs h3{
    margin-bottom: 15px;
    font-size: 17px;
    text-transform: capitalize;
}
.pdr_right.price-fixed{ position: fixed;/*background: #fff;*/ background: #FCF5D5; padding: 10px; z-index: 9; top: 140px;    box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07); width: auto;display: flex; justify-content: space-between;     border: 3px solid #6e1d24; border-top: 0;margin: 0} 
.pdr_right.price-fixed .fix-only-lhs{display: block; padding-right: 30px;text-align: left}
.pdr_right.price-fixed .flavours, .pdr_right.price-fixed h5{ display: none}
.pdr_right.price-fixed .product-min-pax {font-size: 12px; margin: 2px 0 0px;}
.pdr_right.price-fixed h4 {margin-bottom: 5px;font-size: 20px}
.pdr_right.price-fixed .qty_bx { width: 110px; height: 30px;}
.pdr_right.price-fixed .proqty_input { line-height: 26px;font-size: 15px;}



.additionals {
    padding-top: 10px;
    margin-bottom: 15px;
}
.instruction h5 {
    margin-bottom: 10px;
    text-transform: inherit;
}
.product_details_popup a.button.add_to_cart {
    font-size: 16px;
    margin: 100px 0 0;
    padding: 15px 24px 15px;
    letter-spacing: 0.5px;
}

.det_inner_content .show_details {background: #8f0f33; margin: 0 auto;min-width: 128px;    padding: 12px 6px 12px;}
.det_inner_content .prd_close {color: #6e1d24;font-family: 'avantgarde_bk_btdemi'; text-transform: uppercase;}
.det_inner_content .show_details:hover {background: #ffdf00;color: #000;}
.det_inner_content .prd_close span{text-decoration: underline;}
.det_inner_content .product_desc{
    color: #353535;
    font-size: 14px;
    margin: 0 0 20px 0;
    line-height: 1.3;
    flex: 1 1 auto;
}
.det_inner{height: 100%;}
.modifier-btn-grp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.modifier-btn-grp .button {
    width: 49%;
    padding: 12px 5px;
    font-size: 14px;
    margin: 0 0 5px 0;
}
.modifier-btn-grp .mdf-done.button {background: #ffdf00;color: #000;}
.modifier-btn-grp .mdf-done.button:hover {background: #8F0F33;color: #fff;}
.custom_logo_banner textarea.form-control {
    height: 80px;
    border-radius: 5px;
    margin-bottom: 0px;
    padding: 10px;
    font-size: 13px;
}
.custom_logo_banner .input_desc{font-size: 12px}
.event_timing .tooltip_link {
    width: 21px;
    height: 19px;
}
.order_card{ border: 1px solid #8f0f33; border-radius: 5px; overflow: hidden; margin-bottom: 10px; }
.event_timing {
    border-bottom: 1px solid #e4e4e4;
    padding: 15px 15px 10px 15px;
}
.event_timing .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event_timing .form-group .input_field {
    width: calc( 100% - 32px );
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event_timing .form-control{ margin-bottom: 0; height: 40px; border-radius: 4px; padding: 6px 12px;}
.event_timing .form-control.required{ border-color:  #e4000d }
.event_timing .custom_select:after{    width: 30px; height: 38px;border-top-right-radius: 5px; border-bottom-right-radius: 5px}
.event_timing .form-group .input_field .custom_select {
    width: calc( 100% - 110px );
}
.event_timing label{ color: #353535;font-family: 'Helvetica';}
.det_inner_image img {
    width: 100%;
    object-fit: contain;
    height: 100%;
}
img.no-pro {object-fit: scale-down;}
.package_pdfico a {
    color: #6e1d24;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
}

.package_pdfico a i {
    margin: 0 5px 0 0;
    font-size: 16px;
}
.file_details {
    font-size: 13px;
    margin: 5px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file_details a {
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-left: 5px;
}
.file_details a img{width: 10px}
.products_cart {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
}
.products_cart img{ margin-bottom: 10px }
.order_card.light{ background: #f9f9f9;border: 1px solid #e4e4e4;}
.order_card.light .order_card_head{ background: #dcdcdc;  }
.order_card.light .order_card_head h5 { color: #464646;}
.order_tooltip {
    padding: 20px 12px 20px;
    width: 230px;
    letter-spacing: 0.3px;
}
.order_tooltip p:first-of-type{ font-family: 'avantgarde_bk_btdemi'; text-transform: uppercase; }
.det_inner_content{ border: 2px solid #fff; border-top: 0; }
.product_det_cards ul li.det_card.selected { border-color: #8F0F33;background: #F9F9F9;}
.downalod-quote {
    text-align: center;
    padding: 15px 0 0 0;
}
.downalod-quote a{ font-family: 'Myriad Pro'; text-decoration: underline; color: #fff;  text-transform: uppercase;}
.downalod-quote a:hover {color: #ffdf00;}
.tnk-you .products_info_item .cart_left h4 {padding-left: 0px;}
.products_info_item .cart_left h4 {
    font-family: 'Helvetica';
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 2px;
    color: #161616;
    position: relative;
    padding-left: 20px;
}
.receipt_cateringpopup .pouup_in .products_info_item .cart_left h4{ padding-left: 0 }
.receipt_cateringpopup .pouup_in .cart_indvl_price{ display: block; clear: both }
.receipt_cateringpopup .pouup_in .pkg-list-lhs > div{ padding-right: 0 }

.products_info_item .cart_left h4 a{
    position: absolute;
    left: 0;
    top: 0px;
    color: #161616;
    font-size: 15px;
}
.products_info_options p{margin: 0 0 2px 0;
    color: #959595;
    font-size: 13px;
    padding-left: 10px; font-style: italic}
.products_info_options span b {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    display: block;
    padding: 10px 0 0 0;
    font-size: 13px;
}
.product-details.products_info_item{ padding-top: 15px; }
.cart_indvl_price{ margin: 0; color: #161616; font-size: 16px; font-family: 'avantgarde_bk_btdemi';}
.cart_indvl_price sup{top: 0;}
.cart_right .cart-counter .product_quantity {
    height: 100%;
    line-height: 27px;
    border-right: 2px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 2px solid #e0e0e0;
        font-size: 13px; font-family: 'avantgarde_bk_btbook'; font-weight: normal;text-align: center;
    /*background: #fff;*/background: #FCF5D5;
}
.cartaction_checkout{ text-align: center; background: #8F0F33; color: #fff; display: block; border-radius: 3px ; -webkit-border-radius: 3px ; -moz-border-radius: 3px ; -ms-border-radius: 3px ; -o-border-radius: 3px ; }
.cartaction_checkout:hover{ background: #ffdf00; color: #000; }
.products_total sup{top: -1px;margin-right: 2px;}
.add-breaktime {
    font-family: 'avantgarde_bk_btdemi';
    text-decoration: underline;
    color: #464646;
    text-transform: capitalize;
    font-size: 14px;
    display: block;
    margin: 0 auto;
    text-align: center
}
.product_container{ font-family: 'Open Sans', sans-serif;}
.additionals ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.cart-settup-list, .products_info_item{ padding: 5px 15px }
.cart-settup-list ul{list-style: none; padding: 0;display: block;}
.cart-settup-list ul li {
    padding: 5px 0px 10px 0;
}
.cart-settup-list ul li:last-child{padding-bottom: 10px}
.pkgslist_addition{ font-size: 13px; color: #959595;}
.pkgslist_addition p{ font-size: 13px; color: #959595; margin: 0 0 1px 0; }
.pkgslist_addition p:last-of-type{margin-bottom: 0}

.pkg-list-lhs {
    display: flex;
    justify-content: space-between;
}
.pkg-list-lhs label {
    color: #161616;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1;
}
.pkg-list-lhs > div{ position: relative;padding-right: 20px; line-height: 1;}
.cart-package-info:nth-child(even){ background: #f8f8f8; border-top: 1px solid #e4e4e4; }
.pkg-list-lhs > div a.remove{ top: 1px; right: 0;}
.pkg-list-lhs > div .price sup{ top: -1px; }
.pkg-list-lhs > div .price{ color: #161616; font-family: 'avantgarde_bk_btdemi';}
.instruction .spl_instruction{ height: 100px; border-radius: 5px; }
.additionals h5{ text-transform: capitalize;margin-bottom: 10px;}
.product_acc_grp {
    margin-bottom: 30px;
}
ul.additionals-deliverylist {
    padding: 15px 0 10px 30px;
}
.additionals_setuplist > li{ padding-bottom: 15px;    }
.additionals_setuplist > li .custom_radio span{     font-weight: 600; } 
ul.additionals-deliverylist li{ margin-bottom: 15px; }
.addon_checklist li{ margin-bottom: 10px; }
ul.additionals-deliverylist li:last-child, .addon_checklist li:last-child{margin-bottom: 0}
ul.additionals-deliverylist.equipment_listing_div{ padding: 0px 0 10px 0px;}
ul.additionals-deliverylist li .custom_checkbox span{ line-height: 1.4; }
ul.additionals-deliverylist li .custom_checkbox span:before, .addon_checklist li .custom_checkbox span:before{ border-radius: 3px; width: 16px; height: 16px; top: 4px; }
ul.additionals-deliverylist li .custom_checkbox input[type=checkbox]:checked + span:before, .addon_checklist li .custom_checkbox input[type=checkbox]:checked + span:before{ background: url(../images/tick-16.png) no-repeat;}
.addon_popup-catering{ max-width: 780px; }
.addon_popup-catering .addon_linfo h4 {
    text-transform: capitalize;
    font-size: 17px;
    margin-bottom: 10px;
}
.addon_poptop_header h3{ font-size:18px; }
.additionals_equipmentlist .qty_val, .addon-lright-parent .addon_qty_lable, .combo_qtycount .qty_val {
    height: 100%;
    line-height: 28px;
    font-size: 14px;
    border-right: 1px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 1px solid #e0e0e0;text-align: center
}
.additionals_equipmentlist .counter{margin-bottom: 5px}
ul.additionals_equipmentlist li{ margin-bottom: 20px}
.addon_poptop_header h3 small {
    font-size: 80%;
    text-transform: capitalize;
    margin-left: 10px;
    font-family: 'avantgarde_bk_btbook';
    color: #4e4e4e;
}
.addon_poptop_header h3 small {
    font-size: 80%;
    text-transform: capitalize;
    margin-left: 10px;
    font-family: 'avantgarde_bk_btbook';
    color: #4e4e4e;
}

.addon_listrw {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px
}

.addon_linfo {
    width: calc( 100% - 160px );
}

.addon-lright-parent {
    width: 150px;
}
.addon_price{  font-family: 'avantgarde_bk_btdemi'; font-size: 16px;}
.addon-lright-parent .btn {    padding: 10px 12px; line-height: 1; font-size: 14px;margin: 5px 0 0 0; background: #282828}
.addon-lright-parent .btn:hover{ color: #fff; background: #8F0F33 }
.addon_lright { display: flex; flex-flow: column wrap; align-items: flex-end;}
.addon_popbot {
    text-align: right;
}

.addon_popbot .button {
    margin: 10px 0 0 10px;
}
.act-remove-topdiv .delete-act-icon{ color: #000; }
.address_linfo {
    margin-bottom: 10px;
}
.catering-checkout-total .order_det{ padding: 0 15px; }
.catering-checkout-total .cart_footer{padding: 10px 15px 0 15px;flex-grow: 1;display: flex;align-items: flex-end;}
.catering-checkout-total .cart_footer .cart_footer_inner_item {width: 100%;}
.btn_big {
    display: block;
    padding: 12px 10px;
    font-size: 16px;
}
.cdd-details-rhs .event_timing .form-group .input_field .custom_select{ width: calc( 100% - 220px );}
.cdd-details-rhs .products_info_item .cart_left h4{padding-left: 0}
.cdd-details-rhs .event_timing{padding-bottom: 5px; border-bottom: 0}
.catering-checkout-total .cdd-details-rhs{font-family: 'Open Sans', sans-serif;}
.no-recrds-found.loader-main-cls{ min-height: 250px;left: 50%;}
.no-recrds-found.loader-main-cls:empty {display: block;}
.loader-sub-div:empty {display: block;}
.no-recrds-found.loader-main-cls .loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid #ececec;
    border-top-color: #ffdf00;
    border-radius: 50%;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.modal.fade{opacity: 1;}

.hcart_dropdown .pkgslist_additionrow {
    margin-bottom: 15px;
}
.hcart_dropdown .pkgslist_additionrow:last-child{margin-bottom: 0}
.hcart_dropdown .cart-settup-list ul li:last-child{padding-bottom: 5px}
.hcart_dropdown .cart-settup-list ul li label{     color: #161616;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1;}

.popup_product_details{ width: 100%; max-width: 1080px; }
.modal-backdrop.show {
    opacity: .5;
}
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}
select option:disabled{ color: rgba(0,0,0,0.4) }
.cdd-details-rhs .pkgslist_additionrow{padding-bottom: 10px} 
.cdd-details-rhs .pkgslist_additionrow:last-child{padding-bottom: 0}
.cart-settup-list ul li > label{ padding-bottom: 5px;    color: #161616;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: uppercase; }
.slick-slider .products_sidebar_content a{     padding: 10px 15px; }
.products_sidebar_inner .slick-slider{ display: none}
.additionals .instruction {
    padding-top: 20px;
}
.product-edit-half{display: flex; flex-wrap: wrap; justify-content: space-between}
.product-lhs .product-edit-half a{ width: 49%; margin: 10px 0 0 0 !important;  font-size: 17px  !important;padding: 13px 10px 13px !important;
    letter-spacing: 0.5px; }
.mobile-choose-option-hover > ul{ padding: 0; list-style: none}
.mobile-choose-option-hover ul li ul{    
    background: #ececec;
    list-style: none;
    padding: 10px 0;
    display: block;
 } 
.mobile-choose-option-hover > ul > li > a{
    padding: 5px 5px;
    position: relative;
    text-align: center;
    font-family: 'avantgarde_bk_btdemi';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #fbaa1a;
}
.mobile-choose-option-hover > ul > li:last-child > a{
    color: #979797;
    background: #292929;
}
.mobile-choose-option-hover > ul > li > a img {
    margin-right: 5px;
}

.mobile-choose-option-hover ul li ul li a{    font-family: 'avantgarde_bk_btdemi'; color: #000; font-size: 16px; display: block;padding: 4px 10px}
.mobile-choose-option-hover ul li ul li a:hover{ color: #fbaa1a }
.home-top-about {
    position: relative;
    background: #000000;
    border-top: 3px solid #F5D52B;
    border-bottom: 3px solid #F5D52B;
}

.home-top-about .home-container {
    max-width: 1280px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 20px 0;
}

.home-top-about .about-icon {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(30% - 10%);
    flex: 1 1 calc(30% - 10%);
    min-width: 253.5px;
    text-align: center;
}

.home-top-about .about-txt {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(70% - 10%);
    flex: 1 1 calc(70% - 10%);
    min-width: 260px;
    text-align: center;
}

.home-top-about .about-txt h1 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(16px + (70 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0px 0px 49px rgba(0, 0, 0, 0.16);
    margin: 0;
}

.home-top-about .about-txt h4 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(15px + (27 - 15) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    color: #fff;
    margin: 0 0 15px;
}

.home-top-about .about-txt p {
    font-family: 'CenturyGothic';
    font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #fff;
    max-width: 550px;
    margin: 0 auto;
}

.client-sec {
    position: relative;
    background: #fff;
    margin: 140px 0 0;
    padding-bottom: 200px;
}

.client-sec::before {
    content: "";
    position: relative;
    width: 0;
    height: 0;
    border-top: 160px solid transparent;
    border-right: 100vw solid #fff;
    border-bottom: 11px solid transparent;
}

.client_riv {
    padding: 50px 0;
}

.client_riv .client_riv_innr {
    max-width: 1380px;
    margin: 0 auto;
}

.client_riv .client_riv_title {
    display: block;
    text-align: center;
}

.client_riv .client_riv_title h3 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(18px + (59 - 18) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    color: #050505;
    text-transform: capitalize;
    margin-bottom: 45px;
}

.client_riv .client_riv_title p {
    color: #1C1C1C;
    font-size: 15px;
    margin: 0 0 50px;
}
.client_riv .client_riv_slider .slick-slide > div {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.client_riv .client_riv_slider .client_riv_item {
    position: relative;
    text-align: center;
    width: 100%;
    height: auto;
    display: flex !important;
    flex-direction: column;
    overflow: hidden;
}

.client_riv .client_riv_slider .client_riv_item p {
    color: #A6A6A6;
    font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));
    font-family: 'Poppins', sans-serif;
    margin-bottom: calc(25px + (75 - 25) * ((100vw - 300px) / (1600 - 300)));
    text-align: left;
}

.client_riv .client_riv_slider .client_riv_item .client_riv_icon {
    flex-grow: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
}

.client_riv .client_riv_slider .client_riv_item .client_riv_icon img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
}

.client_riv .client_riv_slider .client_riv_item .client_riv_icon .client_riv_txt {
    margin-left: 15px;
}

.client_riv .client_riv_slider .client_riv_item h5 {
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-size: 17px;
    color: #5C5C5C;
    margin: 0 0 5px 0;
}

.client_riv .client_riv_slider .client_riv_item .client_riv_stitle {
    color: #5C5C5C;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    margin: 0 0 5px;
}

.client_riv .client_riv_slider .slick-active.slick-center.slick-current p,
.client_riv .client_riv_slider .slick-active.slick-center.slick-current h5 {
    color: #0D0B06;
}

.client_riv .client_riv_slider .slick-active.slick-center.slick-current {
    cursor: default;
}

.client_riv .client_riv_slider .slick-slide.slick-active {
    padding: 0 44px;
    cursor: pointer;
}

.client_riv .client_riv_slider .slick-slide {
    padding: 0 60px;
}

.client_riv .client_riv_slider .slick-slider .slick-dots {
    display: none !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 0;
}

.client_riv .client_riv_slider .slick-slider .slick-dots li {
    list-style: none;
}

.client_riv .client_riv_slider .slick-slider .slick-dots li button {
    font-size: 0;
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0 3px;
    border-radius: 50%;
    background: #000000;
}

.client_riv .client_riv_slider .slick-slider .slick-dots .slick-active button {
    background: #8f0f33;
}

.client-abt {
    position: relative;
    background: #FCF5D5;
    padding: 50px 0 15px;
    margin-bottom: 150px;
    z-index: 5;
}

.client-abt::before {
    content: "";
    position: relative;
    width: 0;
    height: 0;
    border-top: calc(75px + (210 - 75) * ((100vw - 300px) / (1600 - 300))) solid #fff;
    border-left: 100vw solid #FCF5D5;
    border-bottom: 11px solid transparent;
    z-index: -1;
}

.client-abt::after {
    content: "";
    background: #FCF5D5;
    height: 200px;
    width: 100%;
    position: absolute;
    bottom: -82px;
    left: 0;
    right: 0;
    transform: skewY(356deg);
    z-index: -1;
}

.client-abt .container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.client-abt .client-abt-txt {
    flex: 1 1 calc(60% - 10px);
    min-width: 300px;
}

.client-abt .client-abt-txt h6 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(15px + (27 - 15) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 600;
    margin: 0 0 10px;
    text-transform: capitalize;
}

.client-abt .client-abt-txt h3 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(18px + (59 - 18) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    margin: 0 0 15px;
}

.client-abt .client-abt-txt p {
    font-family: 'CenturyGothic';
    font-size: 17px;
    margin: 0;
    max-width: 570px;
}

.client-abt .client-abt-txt .client-btn {
    font-family: 'Poppins', sans-serif;
    font-size: calc(15px + (19 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.client-abt .client-abt-img {
    position: relative;
    flex: 1 1 calc(40% - 10px);
    min-width: 300px;
}

.client-abt .client-abt-img .client-img-1 {
    position: relative;
    z-index: 10;
    top: -95px;
    margin-bottom: -40px;
    box-shadow: 1px 7px 10px #00000063;
    max-width: 100%;
}

.client-abt .client-abt-img .client-img-2 {
    position: absolute;
    top: -50%;
    right: 0;
    z-index: 0;
    box-shadow: 1px 7px 10px #00000063;
    max-width: 100%;
}

.footer-nav-info .container-full {
    max-width: 1340px;
}

.footer-nav-info .foot-row {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
}

.footer-nav-info .foot-row .foot-sub-col-row {
    flex: 1 1 75%;
    max-width: 75%;
    display: flex;
    flex-flow: row wrap;
}

.footer-nav-info .foot-row h5 {
    font-family: 'Poppins', sans-serif;
    font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1600 - 300)));
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 0 5px;
}

.footer-nav-info .foot-row .foot-col-logo {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* .footer-nav-info .foot-row .foot-col-logo .foot-logo {
    flex: 1 1 28.4%;
    max-width: 380px;
    min-width: 228px;
    display: flex;
    justify-content: center;
}
.footer-nav-info .foot-row .foot-col-logo .foot-logo img {max-width: 228px;} */
.footer-nav-info .foot-row .foot-col-add {
    flex: 1 1 15%;
    max-width: 376px;
    min-width: 280px;
}

.footer-nav-info .foot-row .foot-col-add .foot-col-row {
    display: flex;
    align-items: flex-start;
    margin: 0 0 15px;
}
.footer-nav-info .foot-row .foot-col-add .foot-col-row.foot-row-link {align-items: center;}
.footer-nav-info .foot-row .foot-col-add .foot-col-row .foot-col-ico {
    background: #8F0F33;
    width: 66px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.footer-nav-info .foot-row .foot-col-add .foot-col-row .foot-col-txt {
    width: calc(100% - 66px);
    margin-left: 10px;
    margin-right: 30px;
}

.footer-nav-info .foot-row .foot-col-add .foot-col-row.foot-row-link .foot-col-txt {
    margin-right: 0;
}

.footer-nav-info .foot-row .foot-col-add .foot-col-row .foot-col-txt p {
    font-family: 'CenturyGothic';
    font-size: 15px;
    color: #fff;
    margin: 0 0 5px;
}

.footer-nav-info .foot-row .foot-col-add .foot-col-row .foot-col-txt .foot-link {
    font-family: 'CenturyGothic';
    font-size: 15px;
    color: #fff;
}
.footer-nav-info .foot-row .foot-col-add .foot-col-row .foot-col-txt .foot-link:hover {color: #ffdf00;}
.footer-nav-info .foot-row .foot-total-col {
    flex: 1 1 60%;
    max-width: 582px;
    min-width: 421px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.footer-nav-info .foot-row .foot-col-link {
    flex: 1 1 33.333%;
    max-width: 194px;
    min-width: 140px;
}

.footer-nav-info .foot-row .foot-col-link h5 {
    margin: 0 0 20px;
}

.footer-nav-info .foot-row .foot-col-link .foot-sub-link {
    padding: 0;
}

.footer-nav-info .foot-row .foot-col-link .foot-sub-link li {
    margin: 0 0 15px;
    list-style: none;
}

.footer-nav-info .foot-row .foot-col-link .foot-sub-link li a {
    font-family: 'CenturyGothic';
    font-size: 15px;
    color: #fff;
}

.footer-nav-info .foot-row .foot-col-link .foot-sub-link li a:hover {
    color: #8F0F33;
}

.footer-nav-info .foot-row .foot-col-social {
    flex: 1 1 15%;
    max-width: 380px;
    min-width: 228px;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 543px;
}

.footer-nav-info .foot-row .foot-col-social .foot-logo {
    max-width: 228px;
    margin: 0 auto 25px;
}

.footer-nav-info .foot-row .foot-col-social .foot-soc-link {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0;
}
.footer-nav-info .foot-row .foot-col-social .foot-soc-link li {
    margin: 5px;
    list-style: none;
}
.footer-nav-info .foot-row .foot-col-social .foot-soc-link li:first-child {
    margin: 5px 5px 5px 0px;
}
.footer-nav-info .foot-row .foot-col-social .foot-soc-link li a:hover img {
    max-width: 90%;
}
.contact_wrap .white_bgbx { /*background: #fff;*/ background: #FCF5D5; padding: 20px; border-radius: 5px;}
.contact_wrap {margin-bottom: 50px;}
.contact_wrap .form-control{background: #FCF5D5;}
.package_details_left .slick-slider .slick-list .slick-slide > div {width: 100%;}

.swal-button-container .swal-button{
    color: #FFFFFF;
    background: #8F0F33;
    border:none;
}

.swal-button-container .swal-button:hover{
    color: #000;
    background: #ffdf00;
}
.banner-info{
    text-align: left;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    right: 0;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}
.banner-info h2, .banner-info p{
    font-family: 'Poppins', sans-serif;
     color: #ffe117; 
     font-weight: 700; 
     /* opacity: 0.6; */
}
.banner-info h2 {
    font-size: 98px;
    line-height: 1.2;
    margin-bottom: 10px;
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
    font-family: 'bestloversregular';
    text-shadow: 1px 1px 2px rgb(0 0 0 / 34%);
}
.banner-info p {
    margin: 0;
    line-height: 1.2;
    font-size: 30px;
    letter-spacing: 2px;
    font-family: 'Abril Fatface', cursive;
}
.banner-info h2 span { 
    /* background: url(http://www.color-hex.com/palettes/26323.png) -20px -20px repeat;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    display:block; */
}

.banner-info h2 span {
    display: block;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 1.2;
    font-family: 'futuramedium';
    color: #fff;
}

.home-banner .bg-overlay {
    background: rgb(4 4 4 / 44%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.about-banner p{
    font-family: 'bestloversregular';
}

.product-menu-listing .catering-product-list .products_sidebar_inner{
    width: 83%;
}

.menu-section-catering.menu-section-left .more-menu {width: 20%;}
.menu-section-catering.menu-section-left label.open_more_category {background: transparent;height: auto;}
.menu-section-catering.menu-section-left .more-menu-parent {
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.products-sidebar-more-menu {
    width: 17%;
    margin: 0px 0 5px;
}
.products-sidebar-more-menu-parent {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: flex-end;
    text-transform: capitalize;
    font-size: 16px;
    line-height: normal;
    height: 100%;
    margin: 0 0px 0 0;
    color: #060801;
    background: #FCF5D5;
    padding: 10px;
    text-align: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    transition: 0.3s linear all;
    border-radius: 5px;
}
.products-sidebar-more-menu-parent.sub-active,
.products-sidebar-more-menu-parent:hover {
    color: #fff;
    background: #8f0f33;
}
.products-sidebar-more-menu-parent.sub-active label.products_sidebar_open_more_category,
.products-sidebar-more-menu-parent:hover label.products_sidebar_open_more_category {
    color: #fff;
}
.products-sidebar-more-menu-parent.sub-active:hover, {
    background: #8f0f33;
}
label.products_sidebar_open_more_category{
    width: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 25px;
    color: #000;
    background: #FCF5D5;
    position: relative;
    border-radius: 5px;
    display: flex;
    right: 10px;
    height: 100%;
    justify-content: center;
    align-items: center;
}
ul.products_sidebar_more_categor_info{
    padding: 0;
    list-style: none;
    min-width: 217px;
    max-width: 320px;
    background: #fff;
    position: absolute;
    /* right: 0; */
    z-index:1;
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 3px;
    /* top: 100%; */
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 1px 2px 3px #00000040;
}
.products-sidebar-more-menu-parent{
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    min-height: 56px;
    margin: 0 0.5% 5px 0;
}
label.products_sidebar_open_more_category{
    background: transparent;
    height: auto;
    justify-content: flex-end;
}
 ul.products_sidebar_more_categor_info{
    padding: 15px;
}

ul.products_sidebar_more_categor_info .menu-list-intvl{
    padding: 10px 0;
}
ul.products_sidebar_more_categor_info .menu-list-intvl a{
    padding: 12px 20px;
    border-bottom: 1px solid #f3f3f3;
    color: #0a0b10;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    color: #000;
}
ul.products_sidebar_more_categor_info .menu-list-intvl a:hover {color: #8f0f33;}

.home-banner .slick-slide img{
    position: relative;
    width: 100%; 
}
.home-banner .slick-slide .home-logo img{
    width: 100px;
    height: 100%;
    transition: 0.3s linear all;
    margin: 0 auto 15px;
}

.banner-innerdiv {
    max-width: 700px;
    padding: 20px 0;
}

.home-banner .bg-overlay {
    background: rgb(4 4 4 / 44%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.catering_cart_list .product_delivery.product-card .form-control{
    background: #FCF5D5;
}

.product-lhs-inner .main_product_index .instruction .form-group .spl_instruction{
    background: #FCF5D5;
}

.catering-checkout-total .checkout-body-section .form-control{
    background: #FCF5D5;
}

.order_card_body .event_timing .form-control{
    background: #FCF5D5;
}

.cdd-details-rhs.fl .chekout_cart_bar .event-date .form-control{
    background: #FCF5D5;
}

.cdd-details-lhs.fl .checkout-billing-address .custom_checkbox span:before,
.catering-checkout-total .tarms_chkbox_div span:before {
    background: #FCF5D5;
}

ul.additionals-deliverylist li .custom_checkbox span:before, .addon_checklist li .custom_checkbox span:before{
    background: #FCF5D5;
}

.menu-section-mobile-inner, .mobile-menu-section-left{
    background: #FCF5D5;
}

.abtparent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.abtlhs {
    width: 50%;
    order: 2;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.abtrhs {
    width: 50%;
    position: relative;
    order: 1;
    padding-right: 90px;
}

.abtrhs:after {
    content: '';
    position: absolute;
    width: 35px;
    height: 80%;
    top: 10%;
    z-index: 9;
    background: #fee93e;
}

.abtlhs h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 32px;
}

.abtrhs img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.abtbottom {
    padding: 80px 0 0 0;
}

.abtbottom ul {
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0 80px;
    flex-wrap: wrap;
}

.abtbottom ul li {
    width: 45%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.abtbottom ul li h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.abtlhs strong {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}
.policy strong{  font-family: 'Poppins', sans-serif;
    font-weight: bold; }
.policy {
    font-family: 'Poppins', sans-serif;
}

.foot-top{
    margin: 30px 0 30px;
}

.foot-scan {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.foot-scan img{
    width: 120px; height: 120px;
}

.mobile-scan{
    display: none;
}

.logo{
    display: none;
}